import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, UserDetails } from '../utils/types';

const useUser = (userId: string) => {
  const fetchUserDetails = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}/users/${userId}`);
      return data;
    } catch (error : any) {
      throw error.response.data;
    }
  };

  return useQuery<UserDetails, AxiosResponse<APIError>>(
    ['user', userId],
    fetchUserDetails,
    {
      retry: false,
    }
  );
};

export default useUser;
