import styled from 'styled-components';

interface IProps {
  column?: boolean;
  align?: string;
  justify?: string;
}

const Flex = styled.div<IProps>`
  display: flex;
  flex-direction: ${(props) => props.column && 'column'};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
`;

export default Flex;
