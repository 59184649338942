import { FC, FocusEvent, InputHTMLAttributes, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { fadeIn, shake } from '../../styles/animations';
import { Text1Css } from './Typography';
import React from 'react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    error?: string;
    showSuccessIndicator?: boolean;
}

const Input2: FC<IProps> = ({
                               type,
                               label,
                               name,
                               placeholder,
                               required,
                               autoFocus,
                               error,
                               value,
                               onChange,
                               className,
                               style,
                               onBlur,
                               showSuccessIndicator = false,
                               ...rest
                           }) => {
    const [focused, setFocused] = useState(autoFocus || false);

    const handleOnBlurEvent = (event: FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <Container
            focused={focused}
            error={error}
            className={className}
            style={style}
        >
            <label htmlFor={name}>
                {label}
                {required && ' *'}
            </label>
            <input
                type={type}
                name={name}
                id={name}
                required={required}
                placeholder={placeholder}
                value={value || ''}
                autoFocus={autoFocus}
                formNoValidate
                onChange={onChange}
                onFocus={() => setFocused(true)}
                onBlur={handleOnBlurEvent}
                className={`${error && 'error'} ${focused && 'focused'}`}
                {...rest}
            />
            {showSuccessIndicator && !focused && value && value !== '' && !error && (
                <StyledCheck />
            )}
            {error && <span className="error">{error}</span>}
        </Container>
    );
};

export default Input2;

const Container = styled.div<{ focused: boolean; error?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 2rem;
  ${Text1Css}

  /* Shake if there is an error */
  ${({ error }) =>
    error &&
    css`
      animation: ${shake} 0.7s ease-out;
    `}

  label {
    font-size: 14px;
    color: #93ACBD;
    
    @media(max-width:576px){
    font-size: 12px;
    }
  }
  
  input {
  padding: 0px 10px;
  width: 100%;
  height: 50px;
  margin-top:8px;
  // margin-bottom:20px;
  font-weight: 600;
  font-size: 16px;
  color: #3f52fd;
  background: #ffffff;
  border: 1.2px solid #88b9db;  
  border-radius: 10px;
  
  &:focus{
    background: #E7F1F8;
    border: 1.2px solid #3f52fd;
    border-radius: 10px;
    outline: none;
  }
  ::placeholder{
   font-weight: 400;
   font-size: 14px;
   color: #93ACBD;
   @media(max-width:576px){
    font-size: 12px;
    }
  }

    /* Error state styles */
    &.error {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red[500]};
    }

    border-radius: 10px;
    color: ${({ theme }) => theme.colors.grey[500]};
    transition: 0.1s;
    
  }
  
    @media(max-width:576px){
      input{
       height:42px;
      }
    }

  span.error {
    margin-top: 0.8rem;
    margin-left: 0.4rem;
    color: ${({ theme }) => theme.colors.red[500]};
  }
`;

const StyledCheck = styled(FaCheckCircle)`
  position: absolute;
  font-size: 2rem;
  right: 1.5rem;
  top: 4.3rem;
  color: ${({ theme }) => theme.colors.blue[500]};
  animation: ${fadeIn} 0.5s;    
`;
