import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import Flex from "../components/Flex";
import { Text1Css, Text2Css } from "../components/Typography";
import OtpInput from "react-otp-input";
import { useMedia } from "../../hooks/useMedia";
import useForm from "../../hooks/useForm";
import { Link, useHistory, useLocation } from "react-router-dom";
import Input2 from "../components/Input2";
import {
  API_ENDPOINT,
  LOGIN_WITH_PHONE_FORM_VALIDATION_SCHEMA,
  REGISTER_FORM_VALIDATION_SCHEMA,
  REGISTER_WITH_PHONE_FORM_VALIDATION_SCHEMA,
} from "../../utils/constants";
import { APIError, FetchResponse, FormFields } from "../../utils/types";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import Toast from "../components/Toast";
import moment from "moment";

function RegisterPage2() {
  const location = useLocation<any>();
  const [loginType, setLoginType] = useState<any>(true);
  const phone_number = location.state?.phone_number;

  useEffect(() => {
    phone_number && setLoginType(false);
  }, [phone_number]);
  return (
    <RightBanner className="col-6">
      <Text>Hey, Hello</Text>
      <Heading>Register to Stride Ahead</Heading>
      <Text>
        Already a member?{" "}
        <Span>
          {" "}
          <Link to="/login">Log IN</Link>{" "}
        </Span>
      </Text>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <Button1
          className={loginType && "active"}
          style={{ marginRight: "30px" }}
          onClick={() => {
            setLoginType(true);
          }}
        >
          Email
        </Button1>
        <Button1 // @ts-ignore
          className={loginType === false && "active"}
          onClick={() => setLoginType(false)}
        >
          Phone Number
        </Button1>
      </div>

      {loginType ? (
        <RegisterWithEmail />
      ) : (
        <RegisterWithPhone setLoginType={setLoginType} />
      )}

      <Foot>
        <Text>© 2022, Stride Ahead™</Text>
      </Foot>
    </RightBanner>
  );
}

export default RegisterPage2;

function RegisterWithEmail() {
  const history = useHistory<any>();
  const [isLoading, setLoading] = useState(false);
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState<any>({ type: "", message: "" });

  const actionOnSubmit = async (data: {
    [key in FormFields]?: string;
  }): Promise<APIError[] | void> => {
    setLoading(true);
    // console.log(data);
    try {
      const resp = await axios.post<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/users",
        data
      );
      const re: any = resp.data;
      setMessages({
        type: "success",
        message: re?.data?.message,
      });
      setLoading(false);
      if (resp.data?.success) {
        history.push({
          pathname: "/email_verification",
          state: {
            emailId: data?.email,
            name: `${data?.first_name}  ${data?.last_name}`,
          },
        });
      } else {
        setMessages({
          type: "error",
          message: re?.data?.error[0].email,
        });
      }
    } catch (error: any) {
      console.log("actionOnSubmit::error", error);
      setMessages({
        type: "error",
        message: `${
          error.response?.data?.message
            ? error.response?.data?.message
            : error.response?.data?.error[0]?.email ||
              error.response?.data?.error[0]?.form_level_error
        }`,
      });
      if (
        error.response?.data?.error[0].email === "User with email already exist"
      ) {
        try {
          const resp = await axios.post<FetchResponse<{ message: string }>>(
            API_ENDPOINT + "/login",
            { email: data?.email, password: data?.password }
          );
          const re: any = resp.data;
          setMessages({
            type: "success",
            message: re?.data?.message,
          });
          setLoading(false);
          history.push({
            pathname: "/",
            state: { response: true, message: "Email already exists" },
          });
        } catch (err: any) {
          console.log("actionOnSubmit::error", error);
          setMessages({
            type: "error",
            message: `${
              err.response?.data?.message
                ? err.response?.data?.message
                : err.response?.data?.error[0]?.form_level_error
            }`,
          });
          if (
            err.response?.data?.error[0].form_level_error ===
            "Incorrect email/password"
          ) {
            history.push({
              pathname: "/login",
              state: { existEmail: data?.email, response: false },
            });
          }
        }
      }
      setLoading(false);
      const errors = error.response?.data?.error as APIError[];
      return errors;
    }
  };

  async function apiAuth() {
    try {
      const resp = await axios.get<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/me"
      );
      if (resp?.data?.success) {
        history.push({
          pathname: "/",
        });
      }
    } catch (err) {
      console.log("error");
    }
  }

  useEffect(() => {
    apiAuth();
  }, []);

  const {
    formData,
    setFormData,
    formSubmitResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: REGISTER_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  const isButtonDisabled = () => {
    return !(
      formData.first_name?.value !== "" &&
      formData.first_name?.error === "" &&
      formData.last_name?.value !== "" &&
      formData.last_name?.error === "" &&
      formData.email?.value !== "" &&
      formData.email?.error === "" &&
      formData.password?.value !== "" &&
      formData.password?.error === "" &&
      !isLoading
    );
  };
  useEffect(() => {
    messages.message ? setShowAlert(true) : setShowAlert(false);
  }, [messages]);

  useEffect(() => {
    messages?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [messages]);
  const isSmallScreen = useMedia(["(max-width: 1024px)"], [true], false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        {showAlert && (
          <div
            className={
              alertStyle ? "alert alert-success " : "alert alert-danger"
            }
            role="alert"
          >
            {messages?.message}
          </div>
        )}
        <div style={{ marginTop: "30px" }}>
          <Flex column={isSmallScreen}>
            <div
              style={
                isSmallScreen ? {} : { marginRight: "2rem", width: "100%" }
              }
            >
              <StyleInput
                type="text"
                name="first_name"
                label="First Name"
                placeholder="Enter Your First Name"
                value={formData.first_name?.value}
                error={formData.first_name?.error}
                required
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                showSuccessIndicator={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <StyleInput
                type="text"
                name="last_name"
                label="Last Name"
                placeholder="Enter Your Last Name"
                value={formData.last_name?.value}
                error={formData.last_name?.error}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                showSuccessIndicator={false}
              />
            </div>
          </Flex>

          <StyleInput
            type="email"
            name="email"
            label="Email Address"
            placeholder="Enter Your Email Address"
            value={formData.email?.value}
            error={formData.email?.error}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            showSuccessIndicator={false}
          />

          <StyleInput
            type="password"
            name="password"
            label="Password"
            placeholder="Enter Your Password"
            value={formData.password?.value}
            error={formData.password?.error}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            showSuccessIndicator={false}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginTop: "10px",
          }}
        >
          <StyleCheckBox
            type="checkbox"
            required
            style={{ marginTop: "4px" }}
          />
          <Text>
            {" "}
            I have read and agree to Stride Ahead{" "}
            <Condition>
              {" "}
              <a href="#"> Terms of Service </a>{" "}
            </Condition>{" "}
            and{" "}
            <Condition>
              {" "}
              <a href="#"> Privacy Policy.</a>
            </Condition>
          </Text>
        </div>
        <StyledButton
          type="submit"
          disabled={isButtonDisabled()}
          isLoading={isLoading}
        >
          Continue
        </StyledButton>
      </form>
    </>
  );
}

// @ts-ignore
function RegisterWithPhone({ setLoginType }) {
  const location = useLocation<any>();
  const history = useHistory<any>();
  const [isLoading, setLoading] = useState(false);
  const [phone, setPhone] = useState<string>();
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState<any>({ type: "", message: "" });
  const [showToast, setShowToast] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("");
  const phone_number = location.state?.phone_number;
  const verification_token = location.state?.verification_token;
  let response = location.state?.response;

  const setToast = (success: boolean) => {
    setToastText(`${success && "The Phone number not registered!"}.`);
    setSuccess(!success);
    setShowToast(true);
  };

  const actionOnSubmit = async (data: {
    [key in FormFields]?: string;
  }): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      if (phone_number === phone) {
        const resp = await axios.post<FetchResponse<{ message: string }>>(
          API_ENDPOINT + "/otp-sign-up",
          {
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: `+${phone_number}`,
            verification_token,
          }
        );
        const re: any = resp?.data;
        setMessages({
          type: "success",
          message: re?.message,
        });
        setLoading(false);
        if (resp.data?.success) {
          history.push({
            pathname: "/",
          });
        } else {
          setMessages({
            type: "error",
            message: re?.error[0]?.phone_number,
          });
        }
      } else {
        const resp = await axios.post<FetchResponse<{ message: string }>>(
          API_ENDPOINT + "/otp",
          { phone_number: `+${phone}` }
        );
        if (resp?.data?.success) {
          setMessages({
            type: "success",
            // @ts-ignore
            message: `${resp?.data?.message}`,
          });
        } else {
          setMessages({
            type: "error",
            // @ts-ignore
            message: `${resp?.data?.error[0]?.phone_number}`,
          });
        }
        setLoading(false);
        const re: any = resp?.data;
        console.log(re?.data);
        const dateString = new Date(re?.data?.expiry_time);
        const dateString2 = Date.now();
        console.log(dateString);
        console.log(dateString2);
        const date = moment(dateString);
        const timestamp = date.valueOf();
        const date2 = moment(dateString2);
        const timestamp2 = date2.valueOf();
        const min = (timestamp2 - timestamp) / 1000 / 60;
        history.push({
          pathname: "/register_otp_verification",
          state: {
            phone_number: phone,
            expiryTime: min,
            response: true,
            t_and_c: true,
            details: {
              first_name: data?.first_name,
              last_name: data?.last_name,
            },
          },
        });
      }
    } catch (error: any) {
      console.log("actionOnSubmit::error", error);
      setMessages({
        type: "error",
        message: `${
          error.response?.data?.message
            ? error.response?.data?.message
            : error.response?.data?.error[0]?.phone_number
        }`,
      });
      setLoading(false);
      const errors = error.response?.data?.error as APIError[];
      return errors;
    }
  };

  const {
    formData,
    setFormData,
    formSubmitResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: REGISTER_WITH_PHONE_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  const isButtonDisabled = () => {
    return (
      formData.first_name?.value !== "" &&
      formData.first_name?.error === "" &&
      formData.last_name?.value !== "" &&
      formData.last_name?.error === "" &&
      phone == "" &&
      !isLoading
    );
  };

  //useEffect(() => {
  //    response && setToast(response)
  //    setTimeout(() => {
  //        // @ts-ignore
  //        return history.replace({
  //            ...history.location,
  //            history: {
  //                location:
  //                    {
  //                        state: response = false
  //                    }
  //            }
  //        });
  //    }, 5000)
  //}, [])

  useEffect(() => {
    if (response) {
      setToast(response);
    }

    const timer = setTimeout(() => {
      // @ts-ignore
      history.replace({
        ...history.location,
        state: {
          ...history.location.state,
          response: false,
        },
      });
    }, 5000);

    return () => clearTimeout(timer);
  }, [response, history]);

  useEffect(() => {
    phone_number && setPhone(phone_number);
  }, [phone_number]);
  useEffect(() => {
    messages.message ? setShowAlert(true) : setShowAlert(false);
  }, [messages]);

  useEffect(() => {
    messages?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [messages]);

  const isSmallScreen = useMedia(["(max-width: 1024px)"], [true], false);

  return (
    <>
      <form onSubmit={handleSubmit}>
        {showAlert && (
          <div
            className={
              alertStyle ? "alert alert-success " : "alert alert-danger"
            }
            role="alert"
          >
            {messages?.message}
          </div>
        )}
        <div style={{ marginTop: "30px" }}>
          <Flex column={isSmallScreen}>
            <div
              style={
                isSmallScreen ? {} : { marginRight: "2rem", width: "100%" }
              }
            >
              <StyleInput
                type="text"
                name="first_name"
                label="First Name"
                placeholder="Enter Your First Name"
                value={formData.first_name?.value}
                error={formData.first_name?.error}
                required
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                showSuccessIndicator={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <StyleInput
                type="text"
                name="last_name"
                label="Last Name"
                placeholder="Enter Your Last Name"
                value={formData.last_name?.value}
                error={formData.last_name?.error}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                showSuccessIndicator={false}
              />
            </div>
          </Flex>
          <div style={{ marginBottom: "20px" }}>
            <Label>Phone Number *</Label>
            <StyledPhoneInput
              country={"in"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginTop: "10px",
          }}
        >
          <StyleCheckBox
            type="checkbox"
            required
            style={{ marginTop: "4px" }}
          />
          <Text>
            {" "}
            I have read and agree to Stride Ahead{" "}
            <Condition>
              {" "}
              <a href="#"> Terms of Service </a>{" "}
            </Condition>{" "}
            and{" "}
            <Condition>
              {" "}
              <a href="#"> Privacy Policy.</a>
            </Condition>
          </Text>
        </div>
        <StyledButton
          type="submit"
          disabled={isButtonDisabled()}
          isLoading={isLoading}
        >
          Continue
        </StyledButton>
      </form>
      {showToast && (
        <Toast
          onClose={(showToast: any) => setShowToast(showToast)}
          success={success}
        >
          {toastText}
        </Toast>
      )}
    </>
  );
}

const RightBanner = styled.div`
  padding: 40px 100px;
  width: 100%;
  @media (max-width: 576px) {
    padding: 0px 0px;
  }
`;
const StyledButton = styled(Button)`
  background: #3f52fd;
  border-radius: 10px;
  margin-top: 7rem;
  @media (max-width: 576px) {
    margin-top: 5rem;
    height: 50px !important;
  }
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #073b5f;
  @media (max-width: 576px) {
    font-size: 20px;
  }
`;
const SubHeading = styled.h2``;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #93acbd;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const Span = styled.span`
  font-weight: 600;
  color: #3f52fd;
  a {
    color: #3f52fd;
    text-decoration: none;
  }
`;

const Condition = styled.span`
  a {
    color: #697f8d;
  }
`;

const Button1 = styled.div`
  padding: 11px 32px;
  // background: rgba(18, 115, 183, 0.1);
  border: 1.2px solid rgba(18, 115, 183, 0.1);
  border-radius: 10px;
  font-size: 18px;
  color: #93acbd;
  text-decoration: none !important;
  cursor: pointer;
  @media (max-width: 576px) {
    padding: 8px 26px;
    font-size: 16px;
    // width:98px;
    // height: 42px;
    // display:grid;
    // place-items:center;
  }
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  // line-height: 21px;
  color: #93acbd;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const StyleInput = styled(Input2)`
  // padding: 0px 10px;
  // width: 100%;
  // height: 50px;
  // margin-top:8px;
  // margin-bottom:20px;
  // font-weight: 600;
  // font-size: 16px;
  // color: #3f52fd;
  // background: #ffffff;
  // border: 1.2px solid #88b9db;
  // border-radius: 8px;
  //
  // &:focus{
  //   background: #E7F1F8;
  //   border: 1.2px solid #3f52fd;
  //   border-radius: 10px;
  //   outline: none;
  // }
  // ::placeholder{
  //  font-weight: 400;
  //  font-size: 14px;
  //  color: #93ACBD;
  // }
`;
const ForgotPassword = styled.span`
  ${Text1Css}
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue[500]};
  cursor: pointer;
  @media (max-width: 576px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

const Foot = styled.div`
  @media (min-width: 576px) {
    display: none;
  }
  @media (max-width: 576px) {
    margin-top: 20px;
    text-align: center;
    display: block;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 40px;
    text-align: center;
    display: block;
  }
`;
const StyledPhoneInput = styled(PhoneInput)`
  border: 1.2px solid #88b9db;
  border-radius: 10px;
  margin-top: 8px;
  .react-tel-input.form-control {
    padding-left: 68px !important;
  }
  .flag-dropdown {
    border: none;
    border-radius: 10px 0px 0px 10px;
    background: transparent !important;
    &::after {
      content: "";
      position: absolute;
      left: 100%;
      top: 9px;
      width: 1px;
      height: 24px;
      background: #88b9db;
    }
  }
  .selected-flag {
    width: 50px;
    border-radius: 10px 0px 0px 10px;
    &:hover {
      border-radius: 10px 0px 0px 10px;
      background: transparent !important;
    }
  }
  .open {
    border-radius: 10px !important;
  }
  .flag {
    margin: 0px !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .country-list {
    padding: 5px 15px;
  }

  .country {
    display: flex;
    align-items: end;
  }

  input {
    width: 100% !important;
    height: 50px !important;
    padding: 10px 0px 10px 60px !important;
    font-weight: 600;
    font-size: 16px;
    color: #3f52fd;
    background: #ffffff;
    // border-radius: 8px;
    border: none !important;
    border-radius: 10px !important;

    @media (max-width: 576px) {
      height: 42px !important;
    }

    &:focus {
      background: #e7f1f8;
      border: 1.2px solid #3f52fd;
      border-radius: 10px;
      outline: none;
      .flag-dropdown {
        background: #e7f1f8;
      }
    }
  }
`;
const StyleCheckBox = styled.input`
  border: 1.2px solid #88b9db;
  margin-right: 10px;
`;
