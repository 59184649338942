import React from "react";
import { Route, Link } from "react-router-dom";
import styled from "styled-components";
import AuthGraphic from "../../assets/auth_graphic.png";
import StrideAheadLogo from "../../assets/strideAheadLogoWhite.png";
import { useMedia } from "../../hooks/useMedia";
import Flex from "../components/Flex";
import LoginPage from "../pages/LoginPage";
import LoginPage2 from "../pages/LoginPage2";
import RegisterPage2 from "../pages/RegisterPage2";
import RegisterPage from "../pages/RegisterPage";
import RegisterWithGoogle from "../pages/RegisterWithGoogle";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaCheck } from "react-icons/fa";
// import "swiper/css";
// import "swiper/css/pagination";
import Stride from "../../assets/strideAheadLogoWhite.png";

import "../../Style.css";
import { Pagination } from "swiper";
import DivyaNandan from "../../assets/mentors/DivyaNandan.png";
import SaurabhGupta from "../../assets/mentors/saurabhgupta.png";
import HarshPatodia from "../../assets/mentors/HarshPatodia.png";
import Chestha from "../../assets/mentors/Chesthavashisht.png";
import SumitJain from "../../assets/mentors/SumitJain.jpeg";
import EmailVerification2 from "../pages/EmailVerification2";
import EmailOtpVerification from "../pages/EmailOtpVerification";
import RegisterOtpVerification from "../pages/RegisterOtpVerification";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { AppleLoginProps } from "react-apple-login/dist";

const AuthScreenContainer = () => {
  const renderLogo = useMedia(
    ["(max-width: 1024px)"],
    [
      <Link to="/">
        <img src={StrideAheadLogo} alt="Stride Ahead Logo" />
      </Link>,
    ],
    <img src={AuthGraphic} alt="Auth Graphic" />
  );

  return (
    <Container>
      <Sidebar align="center" justify="center">
        <LeftBar>
          <Row className="">
            <div className="">
              <img src={Stride} style={{ width: "200px" }} alt="" />
            </div>
            <div className="">
              <Nav>
                <Heading>Start your journey with us</Heading>
                <Text>
                  Say goodbye to generic career advice and hello to data-driven
                  insights! Our innovative platform uses quizzes and automation
                  tools to help you:
                </Text>
                <BulletPoints style={{ marginTop: "2rem" }}>
                  <FaCheck
                    size="2rem"
                    color="#77BA55"
                    style={{ marginRight: "2px" }}
                  />{" "}
                  Understand your learners' needs and aspirations
                </BulletPoints>
                <BulletPoints>
                  <FaCheck
                    size="2rem"
                    color="#77BA55"
                    style={{ marginRight: "2px" }}
                  />{" "}
                  Provide personalized guidance and support
                </BulletPoints>
                <BulletPoints>
                  <FaCheck
                    size="2rem"
                    color="#77BA55"
                    style={{ marginRight: "2px" }}
                  />{" "}
                  Enhance learner engagement and satisfaction
                </BulletPoints>
                <BulletPoints>
                  <FaCheck
                    size="2rem"
                    color="#77BA55"
                    style={{ marginRight: "2px" }}
                  />{" "}
                  Drive business growth and success
                </BulletPoints>
              </Nav>
            </div>
            <CarouselContainer style={{ width: "100%" }}>
              <div className="owl-carousel owl-theme">
                <Card className="item">
                  <CardUser className="">
                    <UserInfoCol>
                      <Text
                        style={{
                          lineHeight: "24px",
                          fontSize: "16px",
                          padding: "1rem",
                          fontWeight: "medium",
                        }}
                      >
                        Stride Ahead is invaluable for those spearheading talent
                        transformation. They provide a win-win situation - my
                        learners benefit at no cost, and often, I gain a
                        lifetime mentee.
                      </Text>
                      <div style={{ marginLeft: "10px" }}>
                        <UserTitle>Chestha vashisht</UserTitle>
                        <UserPosition>Founder, Uphill Edu</UserPosition>
                      </div>
                    </UserInfoCol>

                    <img
                      src={Chestha}
                      alt=""
                      style={{
                        width: "140px",
                        borderRadius: "10px",
                      }}
                    />
                  </CardUser>
                </Card>
                <Card className="item">
                  <CardUser className="">
                    <UserInfoCol>
                      <Text
                        style={{
                          lineHeight: "24px",
                          fontSize: "16px",
                          padding: "1rem",
                          fontWeight: "medium",
                        }}
                      >
                        Stride Ahead simplified the evaluation of candidates'
                        soft skills by giving insights into communication,
                        teamwork, and problem-solving abilities, including
                        grammar, speech, and vocabulary checks.
                      </Text>
                      <div style={{ marginLeft: "10px" }}>
                        <UserTitle>Saurabh Gupta</UserTitle>
                        <UserPosition>Founder Verismart.ai</UserPosition>
                      </div>
                    </UserInfoCol>
                    <img
                      src={SaurabhGupta}
                      alt=""
                      style={{
                        width: "140px",
                        borderRadius: "10px",
                      }}
                    />
                  </CardUser>
                </Card>
                <Card className="item">
                  <CardUser className="">
                    <UserInfoCol>
                      <Text
                        style={{
                          lineHeight: "24px",
                          fontSize: "16px",
                          padding: "1rem",
                          fontWeight: "medium",
                        }}
                      >
                        Every time we needed to connect student data from
                        multiple sources to our platform, we needed to loop in
                        complex engineering. That’s now three clicks in Stride
                        Ahead. 
                      </Text>
                      <div style={{ marginLeft: "10px" }}>
                        <UserTitle>Harsh Patodia</UserTitle>
                        <UserPosition>Product Lead, We-ace</UserPosition>
                      </div>
                    </UserInfoCol>
                    <img
                      src={HarshPatodia}
                      alt=""
                      style={{
                        width: "140px",
                        borderRadius: "10px",
                      }}
                    />
                  </CardUser>
                </Card>
              </div>
            </CarouselContainer>
            <div className="">
              <Foot>
                <Text>© 2024, Stride Ahead™</Text>
              </Foot>
            </div>
          </Row>
        </LeftBar>
      </Sidebar>
      <Content align="center" justify="center">
        <RightBar>
          <Route path="/login" component={LoginPage2} />
          <Route path="/register" component={RegisterPage2} />
          <Route
            path="/email_otp_verification"
            component={EmailOtpVerification}
          />
          <Route
            path="/register_otp_verification"
            component={RegisterOtpVerification}
          />
          <Route path="/email_verification" component={EmailVerification2} />
          <Route path="/register_google" component={RegisterWithGoogle} />
          <Route path="/reset_password/:token?" component={ResetPasswordPage} />
          <Route path="/linkedin" component={LinkedInCallback} />
          <Route path="/apple" component={EmailOtpVerification} />
        </RightBar>
      </Content>
    </Container>
  );
};

export default AuthScreenContainer;

const Container = styled(Flex)`
  min-height: 100vh;
  font-family: "Poppins", sans-serif;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    min-height: auto;
  }
`;

const Sidebar = styled(Flex)`
  width: 50%;
  // height:100%;
  background: #3f52fd;
  box-shadow: inset 0px -20px 50px rgba(0, 0, 0, 0.2) !important;
  @media (max-width: 576px) {
    width: 100%;
  }

  @media (min-width: 578px) and (max-width: 1024px) {
    width: 100%;
    // height:100vh;
  }

  // img {
  //   width: 100%;
  //   max-width: 60rem;
  // }
  //
  // @media (max-width: 1024px) {
  //   height: 8rem;
  //   width: 100%;
  //   padding: 1rem 0;
  //
  //   a {
  //     height: 100%;
  //     cursor: pointer;
  //
  //     img {
  //       width: auto;
  //       height: 100%;
  //     }
  //   }
  // }
`;

const Content = styled(Flex)`
  width: 50%;
  // padding: 4rem 0;
  flex-grow: 1;

  @media (max-width: 576px) {
    width: 90%;
    padding: 2rem 0;
  }
  @media (min-width: 578px) and (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
  //  @media (min-width: 1440px){
  //   min-width:800px;
  // }
`;

const LeftBar = styled.div`
  width: 100%;
  @media (min-width: 1025px) {
    max-width: 800px;
    height: 1024px;
  }
`;

const RightBar = styled.div`
  width: 100%;
  @media (min-width: 1440px) {
    max-width: 800px;
  }
`;

const UserTitle = styled.p`
  padding: 0px;
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
`;

const UserPosition = styled.p`
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
`;

const Heading = styled.h1`
  color: #ffffff;
  font-weight: 800;
  font-size: 44px;
  line-height: 52px;
  width: 100%;
  padding-bottom: 20px;
  font-style: normal;

  @media (max-width: 576px) {
    margin-top: 30px;
    margin-bottom: 0px;
    padding-bottom: 10px;
    font-size: 22px;
    line-height: 33px;
    width: 100%;
  }
`;
const Text = styled.p`
  color: #ffffff;
  margin: 0px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.02em;

  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const BulletPoints = styled.p`
  color: #ffffff;
  margin: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.02em;
  margin-top: 2rem;

  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const Row = styled.div`
  width: 100%;
  padding: 40px 0px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const Nav = styled.div`
  // padding-top: 120px;
  padding: 0px 40px;
`;

const Foot = styled.div`
  @media (max-width: 576px) {
    display: none;
  }
  @media (min-width: 578px) and (max-width: 1024px) {
    display: none;
  }
`;
const CarouselContainer = styled.div`
  align-self: center;
  @media (max-width: 576px) {
    display: none;
  }
`;
const Card = styled.div`
  height: fit-content;
  background: rgba(2, 7, 29, 0.1);
  border-radius: 20px;
  padding: 40px;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;s
`;

const CardUser = styled.div`
  display: flex;
`;

const UserInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
