import React, { FC } from 'react';
import styled from 'styled-components';
import { fadeIn } from '../../styles/animations';
import Flex from './Flex';

const Modal: FC = ({ children }) => {
  return (
    <Container align="center" justify="center">
      {children}
    </Container>
  );
};

export default Modal;

const Container = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  animation: ${fadeIn} 0.4s;
`;
