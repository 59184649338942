import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import { fadeIn } from '../../styles/animations';
import { TOAST_DELAY } from '../../utils/constants';
import Flex from './Flex';

// @ts-ignore
const Toast = ({ children , onClose  , success = false, showSuccess = true }) => {
    const delay = TOAST_DELAY;
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);
            onClose(false);
        }, delay);
    }, [delay]);

    return visible ? (
        <Container success={success}>
            {showSuccess && (success ? <StyledCheck /> : <StyledCross />)}
            <DIV>
                {showSuccess && (
                    <div className='status'>{success ? 'Success' : 'Error'}</div>
                )}
                <div>{children}</div>
            </DIV>
            <CloseButton onClick={() => setVisible(false)} success={success}>
                <RiCloseLine />
            </CloseButton>
        </Container>
    ) : (
        <></>
    );
};

export default Toast;

const CloseButton = styled(Flex)<{ success?: boolean }>`
  width: 10%;
  text-align: right;
  justify-content: end;
  font-size: 1.6rem;
  height: 100%;
  cursor: pointer;
  svg {
    height: 2.2rem;
    width: 2.2rem;
    ${({ success }) =>
    success
        ? css`
            color: ${({ theme }) => theme.colors.green[400]};
          `
        : css`
            color: ${({ theme }) => theme.colors.red[400]};
          `}
  }
`;

const Container = styled(Flex)<{ success: boolean }>`
  width: 38rem;
  height: 7.2 rem;
  padding: 1rem 2rem;
  position: fixed;
  font-size: 1.6rem;
  font-weight: 400;
  left: 40%;
  bottom: 2rem;
  border-radius: 5px;
  ${({ success }) =>
    success
        ? css`
          border: 1px solid ${({ theme }) => theme.colors.green[400]};
          background-color: ${({ theme }) => theme.colors.green[200]};
        `
        : css`
          border: 1px solid ${({ theme }) => theme.colors.red[400]};
          background-color: ${({ theme }) => theme.colors.red[300]};
        `}

  @media only screen and (max-width: 1024px) {
    width: 30rem;
    font-size: 1.4rem;
    left: 10%;
  }
`;

const DIV = styled(Flex)`
  flex-direction: column;
  padding-left: 1rem;
  width: 100%;
`;

const StyledCheck = styled(FaCheckCircle)`
  font-size: 2rem;
  width: 6%;
  height: 100%;
  color: ${({ theme }) => theme.colors.green[400]};
  animation: ${fadeIn} 0.5s;
  margin-top: 0.5rem;
`;

const StyledCross = styled(FaTimesCircle)`
  font-size: 2rem;
  width: 6%;
  height: 100%;
  color: ${({ theme }) => theme.colors.red[400]};
  animation: ${fadeIn} 0.5s;
`;