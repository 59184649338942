import React, { useState, useEffect } from "react";
import { useMedia } from "../../hooks/useMedia";
import Flex from "../components/Flex";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import Button from "../components/Button";
import Input2 from "../components/Input2";
import { Text1Css } from "../components/Typography";
import { NavLink, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import useForm from "../../hooks/useForm";
import { useHistory, useLocation } from "react-router-dom";
import {
  API_ENDPOINT,
  REGISTER_WITH_PHONE_FORM_VALIDATION_SCHEMA,
} from "../../utils/constants";
import { APIError, FetchResponse, FormFields } from "../../utils/types";
import axios from "axios";
import Toast from "../components/Toast";
import moment from "moment";

function RegisterOtpVerification() {
  const [otp, setOtp] = useState<any>();
  const { state } = useLocation<any>();
  const history = useHistory<any>();
  const [isLoading, setLoading] = useState(false);
  const [phone, setPhone] = useState<string>();
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState<any>({ type: "", message: "" });
  const [showToast, setShowToast] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("");
  const [delay, setDelay] = useState(0);
  const phone_number = state?.phone_number;
  const userDetails = state?.details;
  let response = state?.response;
  let t_and_c = state?.t_and_c;
  let expiryTime = state?.expiryTime;
  console.log(expiryTime);
  const setToast = (success: boolean) => {
    setToastText(`${success && "Otp Send to your register phone number"}.`);
    setSuccess(success);
    setShowToast(true);
  };

  const actionOnSubmit = async (data: {
    [key in FormFields]?: string;
  }): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      console.log(data);
      if (phone_number === phone) {
        const resp = await axios.post<FetchResponse<{ message: string }>>(
          API_ENDPOINT + "/verify-otp",
          {
            phone_number: `+${phone_number}`,
            otp: otp,
          }
        );
        if (resp?.data?.success) {
          setMessages({
            type: "success",
            // @ts-ignore
            message: `${resp?.data?.message}`,
          });
        }
        // @ts-ignore
        if (resp.data?.data?.verification_token) {
          const re = resp.data?.data;
          const resps = await axios.post<FetchResponse<{ message: string }>>(
            API_ENDPOINT + "/otp-sign-up",
            {
              first_name: data?.first_name,
              last_name: data?.last_name,
              phone_number: `+${phone}`,
              // @ts-ignore
              verification_token: re.verification_token,
            }
          );
          if (resps.data?.success) {
            history.push({
              pathname: "/",
              state: {
                response: true,
                message: "Account Created Successfully",
              },
            });
          } else {
            setMessages({
              type: "error",
              // @ts-ignore
              message: `${resps?.data?.error[0]}`,
            });
          }
          // @ts-ignore
        } else if (resp.data?.data?.refresh_token) {
          history.push({
            pathname: "/",
            state: {
              response: true,
              message: "The phone number already exists",
            },
          });
        }
      } else {
        setMessages({
          type: "error",
          message: "error",
        });
      }
    } catch (error: any) {
      console.log("actionOnSubmit::error", error);
      setMessages({
        type: "error",
        message: `${
          error.response?.data?.message
            ? error.response?.data?.message
            : error.response?.data?.error[0]?.otp
        }`,
      });
      setLoading(false);
      const errors = error.response?.data?.error as APIError[];
      return errors;
    }
  };

  async function apiAuth() {
    try {
      const resp = await axios.get<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/me"
      );
      if (resp?.data?.success) {
        history.push({
          pathname: "/",
        });
      }
    } catch (err) {
      console.log("error");
    }
  }

  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  //useEffect(() => {
  //    expiryTime && setDelay(expiryTime);
  //    setTimeout(() => {
  //        // @ts-ignore
  //        return history.replace({
  //            ...history.location,
  //            history: {
  //                location:
  //                    {
  //                        state: state.expiryTime = 0
  //                    }
  //            }
  //        });
  //    }, 600000)
  //}, [expiryTime])

  useEffect(() => {
    if (expiryTime) {
      setDelay(expiryTime);
    }

    const timeoutId = setTimeout(() => {
      history.replace({
        ...history.location,
        state: {
          ...history.location.state,
          expiryTime: 0,
        },
      });
    }, 600000);

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, [expiryTime]);

  //useEffect(() => {
  //    response && setToast(response);
  //    setTimeout(() => {
  //        // @ts-ignore
  //        return history.replace({
  //            ...history.location,
  //            history: {
  //                location:
  //                    {
  //                        state: state.response = false
  //                    }
  //            }
  //        });
  //    }, 5000)
  //}, [])

  useEffect(() => {
    if (response) {
      setToast(response);
    }

    const timer = setTimeout(() => {
      // Update only the state without changing the other parts of the location
      history.replace({
        ...history.location,
        state: {
          ...history.location.state,
          response: false,
        },
      });
    }, 5000);

    return () => clearTimeout(timer);
  }, [response, history, state]);

  console.log(response);

  useEffect(() => {
    apiAuth();
  }, []);

  const re_send_otp = async () => {
    try {
      const resp = await axios.post<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/otp",
        { phone_number: `+${phone ? phone : phone_number}` }
      );
      setLoading(false);
      const re: any = resp?.data;
      console.log(re?.message);
      const dateString = new Date(re?.data?.Expiry_time);
      const dateString2 = Date.now();
      console.log(dateString);
      console.log(dateString2);
      const date = moment(dateString);
      const timestamp = date.valueOf();
      const date2 = moment(dateString2);
      const timestamp2 = date2.valueOf();
      const min = (timestamp2 - timestamp) / 1000 / 60;
      setDelay(min);
      console.log(re?.message);
      setMessages({
        type: "success",
        message: `${re?.message}`,
      });
    } catch (error: any) {
      console.log("actionOnSubmit::error", error);
      setMessages({
        type: "error",
        message: `${
          error.response?.data?.message
            ? error.response?.data?.message
            : error.response?.data?.error[0]?.phone_number
        }`,
      });
      setLoading(false);
      const errors = error.response?.data?.error as APIError[];
      return errors;
    }
  };

  const {
    formData,
    setFormData,
    formSubmitResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: REGISTER_WITH_PHONE_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });
  const handleChangeOtp = (otp: any) => {
    setOtp(otp);
  };
  const isButtonDisabled = () => {
    return (
      formData.first_name?.value !== "" &&
      formData.first_name?.error === "" &&
      formData.last_name?.value !== "" &&
      formData.last_name?.error === "" &&
      phone == "" &&
      !isLoading
    );
  };

  useEffect(() => {
    userDetails &&
      setFormData({
        ...formData,
        first_name: { value: userDetails.first_name, error: "" },
        last_name: { value: userDetails.last_name, error: "" },
      });
  }, [userDetails]);

  useEffect(() => {
    phone_number && setPhone(phone_number);
  }, [phone_number]);
  useEffect(() => {
    messages.message ? setShowAlert(true) : setShowAlert(false);
  }, [messages]);

  useEffect(() => {
    messages?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [messages]);

  const isSmallScreen = useMedia(["(max-width: 1024px)"], [true], false);

  return (
    <RightBanner>
      <Text>Hey, Hello</Text>
      <Heading>Register to Stride Ahead</Heading>
      <Text>
        Already a member?{" "}
        <Span>
          {" "}
          <Link to="/login">Log IN</Link>{" "}
        </Span>
      </Text>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <Button1 to="/login" style={{ marginRight: "30px" }}>
          Email
        </Button1>
        <Button1 to="" style={{ pointerEvents: "none" }}>
          Phone Number
        </Button1>
      </div>
      {showAlert && (
        <div
          className={alertStyle ? "alert alert-success " : "alert alert-danger"}
          role="alert"
        >
          {messages?.message}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: "30px" }}>
          <Flex column={isSmallScreen}>
            <div
              style={
                isSmallScreen ? {} : { marginRight: "2rem", width: "100%" }
              }
            >
              <StyleInput
                type="text"
                name="first_name"
                label="First Name"
                placeholder="Enter Your First Name"
                value={formData.first_name?.value}
                error={formData.first_name?.error}
                required
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                showSuccessIndicator={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <StyleInput
                type="text"
                name="last_name"
                label="Last Name"
                placeholder="Enter Your Last Name"
                value={formData.last_name?.value}
                error={formData.last_name?.error}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                showSuccessIndicator={false}
              />
            </div>
          </Flex>

          <div style={{ marginBottom: "20px" }}>
            <Label>Phone Number *</Label>
            <StyledPhoneInput
              country={"in"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </div>
        </div>

        <StyleOtpInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={4}
          containerStyle={{ justifyContent: "center", gap: "40px" }}
        />

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Flex>
            <Text> Did’t Recieve the OTP?</Text>
            <OtpReSend onClick={re_send_otp}>RE-SEND</OtpReSend>
          </Flex>
          <Flex>
            <OtpReSend>
              {minutes}:{seconds}
            </OtpReSend>
          </Flex>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginTop: "30px",
          }}
        >
          <StyleCheckBox
            type="checkbox"
            checked={t_and_c}
            required
            style={{ marginTop: "4px" }}
          />
          <Text>
            {" "}
            I have read and agree to Stride Ahead{" "}
            <Condition>
              {" "}
              <a href="#"> Terms of Service </a>{" "}
            </Condition>{" "}
            and{" "}
            <Condition>
              {" "}
              <a href="#"> Privacy Policy.</a>
            </Condition>
          </Text>
        </div>

        <StyledButton
          type="submit"
          disabled={isButtonDisabled()}
          isLoading={isLoading}
        >
          Otp Verify
        </StyledButton>
      </form>
      {showToast && (
        <Toast
          onClose={(showToast: any) => setShowToast(showToast)}
          success={success}
        >
          {toastText}
        </Toast>
      )}
      <Foot>
        <Text>© 2022, Stride Ahead™</Text>
      </Foot>
    </RightBanner>
  );
}

export default RegisterOtpVerification;

const StyleOtpInput = styled(OtpInput as any)`
  margin-top: 20px;
  input {
    width: 56px !important;
    height: 56px;
    padding: 0px 10px;
    font-weight: 600;
    font-size: 16px;
    color: #3f52fd;
    background: #ffffff;
    border: 1.2px solid #88b9db;
    border-radius: 8px;

    &:focus {
      background: #e7f1f8;
      border: 1.2px solid #3f52fd;
      border-radius: 10px;
      outline: none;
    }
  }
  @media (max-width: 576px) {
    input {
      width: 46px !important;
      height: 46px;
    }
  }
`;

const OtpReSend = styled.span`
  font-weight: 500;
  color: #3f52fd;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
`;

const OrContainer = styled(Flex)`
  width: 100%;
  margin-top: 40px;
  color: #93acbd;
  .line {
    display: block;
    height: 2px;
    flex-grow: 1;
    background-color: #93acbd;
  }
`;

const DividerSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const RightBanner = styled.div`
  padding: 40px 100px;
  width: 100%;
  @media (max-width: 576px) {
    padding: 0px 0px;
  }
`;
const StyledButton = styled(Button)`
  background: #3f52fd;
  border-radius: 10px;
  margin-top: 3rem;
  @media (max-width: 576px) {
    margin-top: 5rem;
    height: 50px !important;
  }
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #073b5f;
  @media (max-width: 576px) {
    font-size: 20px;
  }
`;
const SubHeading = styled.h2``;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #93acbd;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const Span = styled.span`
  font-weight: 600;
  color: #3f52fd;
  a {
    color: #3f52fd;
    text-decoration: none;
  }
`;

const Condition = styled.span`
  a {
    color: #697f8d;
  }
`;

const Button1 = styled(NavLink)`
  padding: 11px 32px;
  // background: rgba(18, 115, 183, 0.1);
  border: 1.2px solid rgba(18, 115, 183, 0.1);
  border-radius: 10px;
  font-size: 18px;
  color: #93acbd;
  text-decoration: none !important;

  @media (max-width: 576px) {
    padding: 8px 26px;
    font-size: 16px;
    // width:98px;
    // height: 42px;
    // display:grid;
    // place-items:center;
  }
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #93acbd;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const StyleInput = styled(Input2)`
  // padding: 0px 10px;
  // width: 100%;
  // height: 50px;
  // margin-top:8px;
  // margin-bottom:20px;
  // font-weight: 600;
  // font-size: 16px;
  // color: #3f52fd;
  // background: #ffffff;
  // border: 1.2px solid #88b9db;
  // border-radius: 8px;
  //
  // &:focus{
  //   background: #E7F1F8;
  //   border: 1.2px solid #3f52fd;
  //   border-radius: 10px;
  //   outline: none;
  // }
  // ::placeholder{
  //  font-weight: 400;
  //  font-size: 14px;
  //  color: #93ACBD;
  // }
`;

const StyleCheckBox = styled.input`
  border: 1.2px solid #88b9db;
  margin-right: 10px;
`;

const ForgotPassword = styled.span`
  ${Text1Css}
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue[500]};
  cursor: pointer;
`;

const Foot = styled.div`
  @media (min-width: 576px) {
    display: none;
  }
  @media (max-width: 577px) {
    margin-top: 20px;
    text-align: center;
    display: block;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 40px;
    text-align: center;
    display: block;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  border: 1.2px solid #88b9db;
  border-radius: 10px;
  margin-top: 8px;
  .react-tel-input.form-control {
    padding-left: 68px !important;
  }
  .flag-dropdown {
    border: none;
    border-radius: 10px 0px 0px 10px;
    background: transparent !important;
    &::after {
      content: "";
      position: absolute;
      left: 100%;
      top: 12.5px;
      width: 1px;
      height: 25px;
      background: #88b9db;
    }
  }
  .selected-flag {
    width: 50px;
    border-radius: 10px 0px 0px 10px;
    &:hover {
      border-radius: 10px 0px 0px 10px;
      background: transparent !important;
    }
  }
  .open {
    border-radius: 10px !important;
  }
  .flag {
    margin: 0px !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .country-list {
    padding: 5px 15px;
  }

  .country {
    display: flex;
    align-items: end;
  }

  input {
    width: 100% !important;
    height: 50px !important;
    padding: 10px 0px 10px 60px !important;
    font-weight: 600;
    font-size: 16px;
    color: #3f52fd;
    background: #ffffff;
    // border-radius: 8px;
    border: none !important;
    border-radius: 10px !important;

    @media (max-width: 576px) {
      height: 42px !important;
    }

    &:focus {
      background: #e7f1f8;
      border: 1.2px solid #3f52fd;
      border-radius: 10px;
      outline: none;
      .flag-dropdown {
        background: #e7f1f8;
      }
    }
  }
`;
