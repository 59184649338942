import styled from 'styled-components';

export const ServiceCard = styled.a<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: 16rem;
  background-color: ${(props) =>
    props.color ? props.color : props.theme.colors.blue[500]};
  border-radius: 5px;
  font-size: 2.4rem;
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  margin-right: 4rem;
`;
