import { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentProfile } from '../../hooks/useProfile';
import fetchSSOToken from '../../utils/fetchSSOToken';
import { APIError } from '../../utils/types';
import AuthScreenContainer from '../containers/AuthScreenContainer';
import React from "react";
import Cookies from 'js-cookie';

const UnauthenticatedApp = () => {
  const { isLoading, isError } = useCurrentProfile();
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(history.location.search);

  const redirectUrl = urlSearchParams.get('redirect_to') || Cookies.get('redirect_to');

  const sso = urlSearchParams.get('sso');

  useEffect(() => {
    if (!isLoading && !isError) {
      if (redirectUrl) {
        (async () => {
          // if sso let's fetch token
          let token = null;
          if (sso && sso === '1') {
            try {
              var result = await fetchSSOToken();
              token = result.token;
            } catch(error:any) {
              const errors = error.response.data.error as APIError[];
              return errors;
            }
          }
          let redirectTo = new URL(decodeURIComponent(redirectUrl));
          if (token !== null) {
            redirectTo.searchParams.append('jwt', token);
          }
          window.location.href = redirectTo.href;
        })();
      } else {
        history.push('/');
      }
    }
  }, [isLoading, isError, history, redirectUrl, sso]);

  return (
    <Fragment>
      <AuthScreenContainer />
    </Fragment>
  );
};

export default UnauthenticatedApp;
