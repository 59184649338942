import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { RiPencilLine } from 'react-icons/ri';
import styled from 'styled-components';
import useServices from '../../hooks/useServices';
import { fadeIn } from '../../styles/animations';
import Button from '../components/Button';
import Flex from '../components/Flex';
import Modal from '../components/Modal';
import AddService from '../components/popups/AddService';
import EditService from '../components/popups/EditService';
import { Table, TBody, TD, TH, THead, TR } from '../components/Table';
import { Heading3 } from '../components/Typography';


dayjs.extend(relativeTime);

const ServicesList = () => {
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState<number>();

  const { isLoading, isSuccess, data: services } = useServices();

  const openAddServicePopup = () => {
    setShowModal(true);
  };

  const renderServiceList = () => {
    if (isLoading) {
      return (
        <TR>
          <TD colSpan={2} centered>
            Loading...
          </TD>
        </TR>
      );
    } else if (isSuccess) {
      return services?.map((service, index) => (
        <StyledTR key={service.id}>
          <TD maxWidth="15%" style={{ paddingLeft: '4rem' }}>
            {index + 1}
          </TD>
          <TD>{service.service_name}</TD>
          <TD>{service.service_id}</TD>
          <TD>
            <EditButton
              onClick={() => {
                setId(service.id);
                setShowModal(true);
              }}
            >
              <RiPencilLine />
            </EditButton>
          </TD>
        </StyledTR>
      ));
    }
  };

  return (
    <Container column>
      <Header align="center" justify="space-between">
        <Heading3>Services</Heading3>
        <AddServiceButton onClick={openAddServicePopup}>
          Add Service
        </AddServiceButton>
      </Header>
      <Table>
        <THead>
          <StyledTR>
            <TH style={{ paddingLeft: '4rem' }}>S.No.</TH>
            <TH>Name</TH>
            <TH>Service ID</TH>
            <TH>Edit</TH>
          </StyledTR>
        </THead>
        <TBody>{renderServiceList()}</TBody>
      </Table>
      {showModal && (
        <Modal>
          {id ? (
            <EditService
              serviceId={id}
              onClose={() => {
                setId(undefined);
                setShowModal(false);
              }}
            />
          ) : (
            <AddService onClose={() => setShowModal(false)} />
          )}
        </Modal>
      )}
    </Container>
  );
};

export default ServicesList;

const Container = styled(Flex)`
  width: 100%;
  animation: ${fadeIn} 0.4s;
`;

const Header = styled(Flex)`
  width: 100%;
  padding: 1rem 4rem;
  border-top: 1px solid ${(props) => props.theme.colors.grey[200]};
  border-bottom: 1px ridge ${(props) => props.theme.colors.grey[200]};
  background-color: #fff;
`;

const EditButton = styled.button`
  outline: none;
  border: none;
  height: fit-content;
  padding: 0.5rem;
  padding-bottom: 0;
  border-radius: 4px;
  background: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey[100]};
  }

  font-size: 2rem;
  cursor: pointer;
`;

const AddServiceButton = styled(Button)`
  width: 16rem;
  height: 4rem;
`;

const StyledTR = styled(TR)`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};
`;
