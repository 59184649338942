import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import useForm from "../../hooks/useForm";
import Flex from "../components/Flex";
import { Text1Css, Text2Css } from "../components/Typography";
import OtpInput from "react-otp-input";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Input2 from "../components/Input2";
import {
  API_ENDPOINT,
  LOGIN_FORM_VALIDATION_SCHEMA,
  LOGIN_WITH_PHONE_FORM_VALIDATION_SCHEMA,
} from "../../utils/constants";
import { APIError, FetchResponse, FormFields } from "../../utils/types";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoginWithSocials from "./LoginWithSocials";
import Toast from "../components/Toast";
import moment from "moment";
import Cookies from "js-cookie";

function LoginPage2() {
  const history = useHistory();
  const location = useLocation<any>();
  const [loginType, setLoginType] = useState<any>(true);
  const [otpPage, setOtpPage] = useState<any>(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [toastText, setToastText] = useState<string>("");
  const textValue = location.state?.response;
  const existEmail = location.state?.existEmail;

  const setToast = (success: boolean) => {
    setToastText(
      `${
        success
          ? "Email already exists"
          : "The email already exists, Please login with correct password"
      }.`
    );
    setSuccess(success);
    setShowToast(true);
  };

  async function apiAuth() {
    try {
      console.log("API_ENDPOINT", API_ENDPOINT);
      const resp = await axios.get<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/me"
      );
      if (resp?.data?.success) {
        console.log("resp", resp);
        history.push({
          pathname: "/",
        });
      }
    } catch (err) {
      console.log("error");
    }
  }

  useEffect(() => {
    apiAuth();
  }, []);

  useEffect(() => {
    existEmail && !textValue && setToast(textValue);
  }, [textValue]);

  return (
    <RightBanner>
      <Text>Hey, Hello</Text>
      <Heading>Login In to Stride Ahead</Heading>
      <Text>
        Not a member?{" "}
        <Span>
          {" "}
          <Link to="/register">Register Now</Link>{" "}
        </Span>
      </Text>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "30px" }}>
        <Button1
          className={loginType && "active"}
          style={{ marginRight: "30px" }}
          onClick={() => {
            setLoginType(true);
          }}
        >
          Email
        </Button1>
        <Button1
          // @ts-ignore
          className={loginType === false && "active"}
          onClick={() => setLoginType(false)}
        >
          Phone Number
        </Button1>
      </div>

      {loginType ? (
        <LoginWithEmail />
      ) : (
        <LoginWithPhone setOtpPage={setOtpPage} />
      )}

      <OrContainer align="center">
        <span className="line"></span>
        <DividerSpan style={{ margin: "0 2rem" }}>or continue with</DividerSpan>
        <span className="line"></span>
      </OrContainer>

      <div style={{ width: "100%", marginTop: "20px" }}>
        <LoginWithSocials />
      </div>
      {showToast && (
        <Toast
          onClose={(showToast: any) => setShowToast(showToast)}
          success={success}
        >
          {toastText}
        </Toast>
      )}
      <Foot>
        <Text>© 2022, Stride Ahead™</Text>
      </Foot>
    </RightBanner>
  );
}

export default LoginPage2;

// @ts-ignore
function LoginWithEmail() {
  const history = useHistory();
  const location = useLocation<any>();
  const [isLoading, setLoading] = useState(false);
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState<any>({ type: "", message: "" });
  const existEmail = location.state?.existEmail;

  const actionOnSubmit = async (data: {
    [key in FormFields]?: string;
  }): Promise<APIError[] | void> => {
    console.log("hello");
    setLoading(true);
    console.log(data);
    try {
      const resp = await axios.post<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/login",
        data
      );
      const re: any = resp.data;
      console.log("re", re);
      setMessages({
        type: "success",
        message: re?.data?.message,
      });
      setLoading(false);
      const urlSearchParams = new URLSearchParams(history.location.search) || "";
      console.log("urlSearchParams", urlSearchParams);
      const redirectUrl: any = urlSearchParams.get("redirect_to") || Cookies.get('redirect_to');
      console.log("redirectUrl", redirectUrl);
      let redirectTo = new URL(decodeURIComponent(redirectUrl));
      console.log("redirectTo", redirectTo);
      if (redirectUrl !== null) {
        window.location.href = redirectTo.href;
      } else {
        history.push({
          pathname: "/",
          state: { response: true, message: "logged in successfully" },
        });
      }
    } catch (error: any) {
      console.log(
        "actionOnSubmit::error",
        error.response?.data?.error[0]?.form_level_error
      );
      setMessages({
        type: "error",
        message: `${error.response?.data?.error[0]?.form_level_error}`,
      });
      setLoading(false);
      const errors = error.response?.data?.error as APIError[];
      return errors;
    }
  };

  const {
    formData,
    setFormData,
    formSubmitResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: LOGIN_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  const handleForgotPassword = () => {
    history.push("/reset_password");
  };

  useEffect(() => {
    existEmail &&
      setFormData({
        ...formData,
        email: { value: existEmail, error: "" },
      });
  }, [existEmail]);

  const isButtonDisabled = () => {
    return !(
      formData.email?.value !== "" &&
      formData.email?.error === "" &&
      formData.password?.value !== "" &&
      formData.password?.error === "" &&
      !isLoading
    );
  };

  useEffect(() => {
    messages.message ? setShowAlert(true) : setShowAlert(false);
  }, [messages]);

  useEffect(() => {
    messages?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [messages]);

  return (
    <form onSubmit={handleSubmit}>
      {/*{formSubmitResponse && (*/}
      {/*    <FormStatus align="center" className={formSubmitResponse.status}>*/}
      {/*        {formSubmitResponse.message}*/}
      {/*    </FormStatus>*/}
      {/*)}*/}
      {showAlert && (
        <div
          className={alertStyle ? "alert alert-success " : "alert alert-danger"}
          role="alert"
        >
          {messages?.message}
        </div>
      )}
      <div style={{ marginTop: "30px" }}>
        <StyleInput
          type="email"
          name="email"
          label="Email Address"
          placeholder="Enter Your Email Address"
          value={formData.email?.value}
          error={formData.email?.error}
          required
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
          showSuccessIndicator={false}
        />

        <StyleInput
          type="password"
          name="password"
          label="Password"
          placeholder="Enter Your Password"
          value={formData.password?.value}
          error={formData.password?.error}
          required
          onChange={handleChange}
          onBlur={handleBlur}
          showSuccessIndicator={false}
        />
      </div>
      <Flex justify="flex-end">
        <ForgotPassword onClick={handleForgotPassword}>
          Forgot Password?
        </ForgotPassword>
      </Flex>
      <StyledButton
        type="submit"
        disabled={isButtonDisabled()}
        isLoading={isLoading}
      >
        Sign In
      </StyledButton>
    </form>
  );
}

// @ts-ignore
function LoginWithPhone({ setOtpPage }) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [phone, setPhone] = useState<string>();
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState<any>({ type: "", message: "" });

  const actionOnSubmit = async (data: {
    [key in FormFields]?: string;
  }): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      const resp = await axios.post<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/otp",
        { phone_number: `+${phone}` }
      );
      const re: any = resp?.data;
      console.log(re?.data);
      const dateString = new Date(re?.data?.expiry_time);
      const dateString2 = Date.now();
      console.log(dateString);
      console.log(dateString2);
      const date = moment(dateString);
      const timestamp = date.valueOf();
      const date2 = moment(dateString2);
      const timestamp2 = date2.valueOf();
      const min = (timestamp2 - timestamp) / 1000 / 60;
      setMessages({
        type: "success",
        message: `${re?.data?.message}`,
      });
      setLoading(false);
      history.push({
        pathname: "/email_otp_verification",
        state: { phone_number: phone, expiryTime: min, response: true },
      });
      setOtpPage(false);
    } catch (error: any) {
      console.log("actionOnSubmit::error", error);
      setMessages({
        type: "error",
        message: `${
          error.response?.data?.message
            ? error.response?.data?.message
            : error.response?.data?.error[0]?.phone_number
        }`,
      });
      setLoading(false);
      const errors = error.response?.data?.error as APIError[];
      return errors;
    }
  };

  const { handleSubmit } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: LOGIN_WITH_PHONE_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  const isButtonDisabled = () => {
    return phone == "" && !isLoading;
  };

  useEffect(() => {
    messages.message ? setShowAlert(true) : setShowAlert(false);
  }, [messages]);

  useEffect(() => {
    messages?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [messages]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        {showAlert && (
          <div
            className={
              alertStyle ? "alert alert-success " : "alert alert-danger"
            }
            role="alert"
          >
            {messages?.message}
          </div>
        )}
        <div style={{ marginTop: "30px" }}>
          <Label>Phone Number *</Label>
          <StyledPhoneInput
            country={"in"}
            value={phone}
            onChange={(phone) => setPhone(phone)}
          />
        </div>
        <StyledButton
          type="submit"
          disabled={isButtonDisabled()}
          isLoading={isLoading}
          // onClick={() => setOtpPage(false)}
        >
          Continue
        </StyledButton>
      </form>
    </>
  );
}

const OrContainer = styled(Flex)`
  width: 100%;
  margin-top: 40px;
  color: #93acbd;
  .line {
    display: block;
    height: 2px;
    flex-grow: 1;
    background-color: #93acbd;
  }
`;

const DividerSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const RightBanner = styled.div`
  padding: 40px 100px;
  width: 100%;
  @media (max-width: 576px) {
    padding: 0px 0px;
  }
`;
const StyledButton = styled(Button)`
  background: #3f52fd;
  border-radius: 10px;
  margin-top: 7rem;
  @media (max-width: 576px) {
    margin-top: 5rem;
    height: 50px !important;
  }
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #073b5f;
  @media (max-width: 576px) {
    font-size: 20px;
  }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #93acbd;
  margin: 0;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const Span = styled.span`
  font-weight: 600;
  color: #3f52fd;
  a {
    color: #3f52fd;
    text-decoration: none;
  }
`;

const Button1 = styled.div`
  padding: 11px 32px;
  // background: rgba(18, 115, 183, 0.1);
  border: 1.2px solid rgba(18, 115, 183, 0.1);
  border-radius: 10px;
  font-size: 18px;
  color: #93acbd;
  text-decoration: none !important;
  cursor: pointer;
  @media (max-width: 576px) {
    padding: 8px 26px;
    font-size: 16px;
    // width:98px;
    // height: 42px;
    // display:grid;
    // place-items:center;
  }
`;

const Label = styled.label`
  font-weight: 400;
  font-size: 14px;
  // line-height: 21px;
  color: #93acbd;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const StyleInput = styled(Input2)``;

const ForgotPassword = styled.span`
  ${Text1Css}
  font-weight: 600;
  color: ${({ theme }) => theme.colors.blue[500]};
  cursor: pointer;
  @media (max-width: 576px) {
    font-size: 14px;
    font-weight: 500;
  }
`;

const Foot = styled.div`
  @media (min-width: 576px) {
    display: none;
  }
  @media (max-width: 576px) {
    margin-top: 20px;
    text-align: center;
    display: block;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 40px;
    text-align: center;
    display: block;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  border: 1.2px solid #88b9db;
  border-radius: 10px;
  margin-top: 8px;
  .react-tel-input.form-control {
    padding-left: 68px !important;
  }
  .flag-dropdown {
    border: none;
    border-radius: 10px 0px 0px 10px;
    background: transparent !important;
    &::after {
      content: "";
      position: absolute;
      left: 100%;
      top: 9px;
      width: 1px;
      height: 24px;
      background: #88b9db;
    }
  }
  .selected-flag {
    width: 50px;
    border-radius: 10px 0px 0px 10px;
    &:hover {
      border-radius: 10px 0px 0px 10px;
      background: transparent !important;
    }
  }
  .open {
    border-radius: 10px !important;
  }
  .flag {
    margin: 0px !important;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .country-list {
    padding: 5px 15px;
  }

  .country {
    display: flex;
    align-items: end;
  }

  input {
    width: 100% !important;
    height: 50px !important;
    padding: 10px 0px 10px 60px !important;
    font-weight: 600;
    font-size: 16px;
    color: #3f52fd;
    background: #ffffff;
    // border-radius: 8px;
    border: none !important;
    border-radius: 10px !important;

    @media (max-width: 576px) {
      height: 42px !important;
    }

    &:focus {
      background: #e7f1f8;
      border: 1.2px solid #3f52fd;
      border-radius: 10px;
      outline: none;
      .flag-dropdown {
        background: #e7f1f8;
      }
    }
  }
`;
