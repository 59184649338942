import axios from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import styled from 'styled-components';
import DefaultProfilePicture from '../../assets/default_profile.png';
import useServices from '../../hooks/useServices';
import useUser from '../../hooks/useUser';
import { fadeIn } from '../../styles/animations';
import { API_ENDPOINT } from '../../utils/constants';
import { capitalize, trimUserToken } from '../../utils/helperFunctions';
import Button from '../components/Button';
import Flex from '../components/Flex';
import Modal from '../components/Modal';
import EditUserProfile from '../components/popups/EditUserProfile';
import Select, { Option } from '../components/Select';
import { Table, TBody, TD, TH, THead, TR } from '../components/Table';
import ToggleSwitch from '../components/ToggleSwitch';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const UserDetails = () => {
  const { userId } = useParams<{ userId: string }>();

  const queryClient = useQueryClient();

  const [showEditProfile, setShowEditProfile] = useState(false);
  const [isUserActive, setUserStatus] = useState(false);
  const [userRoles, setUserRoles] = useState<{ [serviceId: string]: string }>(
    {}
  );

  const { data: userDetails, isLoading: isUserDetailsLoading } = useUser(
    userId
  );
  const { data: services, isLoading: isServicesLoading } = useServices();

  const handleLogoutUser = async () => {
    try {
      await axios.post(`${API_ENDPOINT}/users/${userId}/logout`);

      queryClient.invalidateQueries(['users', userId]);
    } catch (error) {
      alert('Something went wrong');
    }
  };

  const handleRoleChange = useCallback(
    async (updatedRoles: { [serviceId: string]: string }) => {
      try {
        console.log(updatedRoles);
        await axios.patch(`${API_ENDPOINT}/users/${userId}/change-role`, {
          user_roles: updatedRoles,
        });

        setUserRoles((roles) => {
          return { ...roles, ...updatedRoles };
        });
      } catch (error : any) {
        console.log(error.response.data);
        alert('Something went wrong');
      }
    },
    [userId]
  );

  useEffect(() => {
    if (userDetails?.user_roles) {
      setUserRoles(userDetails?.user_roles);
    }
  }, [userDetails]);

  if (isUserDetailsLoading || isServicesLoading) {
    return <Container>Loading...</Container>;
  }

  return (
    <Container column>
      <Flex>
        <ProfileContainer column align="center">
          <button
            className="edit-profile"
            onClick={() => setShowEditProfile(true)}
          >
            Edit Profile
          </button>
          <img
            src={userDetails?.profile_pic || DefaultProfilePicture}
            alt={userDetails?.first_name}
          />
          <h1 className="name">
            {userDetails?.first_name} {userDetails?.last_name}
          </h1>
          <h2 className="email">{userDetails?.email}</h2>
          <h2 className="phone">{userDetails?.phone_no}</h2>
          <ToggleSwitch
            labels={['Deactivate', 'Activate']}
            checked={isUserActive}
            onClick={() => setUserStatus(!isUserActive)}
          />
        </ProfileContainer>
        <ServiceTableContainer column>
          <h2 className="header">Services</h2>
          <Table>
            <THead>
              <TR>
                <TH centered>S.No.</TH>
                <TH>Service Name</TH>
                <TH>Service ID</TH>
                <TH>Role</TH>
              </TR>
            </THead>
            <TBody>
              {services?.map((eachService, index) => (
                <TR key={eachService.service_id}>
                  <TD centered maxWidth="15%">
                    {index + 1}
                  </TD>
                  <TD>{eachService.service_name}</TD>
                  <TD>{eachService.service_id}</TD>
                  <TD>
                    <Select
                      value={capitalize(userRoles[eachService.service_id])}
                    >
                      {[
                        eachService.default_user_role,
                        ...eachService.possible_user_roles,
                      ].map((eachPossibleRole, index) => (
                        <Option
                          key={index}
                          onClick={() =>
                            handleRoleChange({
                              [eachService.service_id]: eachPossibleRole,
                            })
                          }
                        >
                          {capitalize(eachPossibleRole)}
                        </Option>
                      ))}
                    </Select>
                  </TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </ServiceTableContainer>
      </Flex>
      <TokenTableContainer>
        <Table>
          <THead>
            <TR>
              <TH centered>Token</TH>
              <TH centered>Login Time</TH>
              <TH centered>Logout Time</TH>
            </TR>
          </THead>
          <TBody>
            {userDetails?.tokens
              .sort((a, b) =>
                dayjs(b.created_at).diff(dayjs(a.created_at), 'second')
              )
              .map((eachToken, index) => (
                <TR key={index}>
                  <TD centered maxWidth="15%">
                    {trimUserToken(eachToken.refresh_token)}
                  </TD>
                  <TD centered>
                    {dayjs(eachToken.created_at).format(
                      'MMM D, YYYY h:mm:ss A'
                    )}
                  </TD>
                  <TD centered>
                    {eachToken.deleted_at ? (
                      dayjs(eachToken.deleted_at).format(
                        'MMM D, YYYY h:mm:ss A'
                      )
                    ) : (
                      <LogoutButton onClick={handleLogoutUser}>
                        Logout User
                      </LogoutButton>
                    )}
                  </TD>
                </TR>
              ))}
          </TBody>
        </Table>
      </TokenTableContainer>
      {showEditProfile && (
        <Modal>
          <EditUserProfile
            userId={userId}
            onClose={() => setShowEditProfile(false)}
          />
        </Modal>
      )}
    </Container>
  );
};

const Container = styled(Flex)`
  width: 100%;
  padding: 2rem;
  animation: ${fadeIn} 0.4s;
`;

const Containers = styled(Flex)`
  background-color: #fff;
  border-radius: 5px;
`;

const ProfileContainer = styled(Containers)`
  width: 35rem;
  height: auto;
  padding: 1rem;
  font-family: 'Poppins';

  img {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 5rem;
    margin-bottom: 2rem;
  }

  .name {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .email,
  .phone {
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 1rem;
  }

  .edit-profile {
    align-self: flex-end;
    border: none;
    outline: none;
    padding: 0.6rem 0;
    background: none;
    font-size: 1.2rem;
    color: ${(props) => props.theme.colors.blue[500]};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const LogoutButton = styled(Button)`
  width: 15rem;
  height: 70%;
`;

const ServiceTableContainer = styled(Containers)`
  margin-left: 2rem;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;

  .header {
    font-size: 2rem;
    font-weight: 500;
    padding: 2rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};
    color: ${(props) => props.theme.colors.grey[600]};
  }
`;

const TokenTableContainer = styled(Containers)`
  width: 100%;
  margin: 2rem 0;
`;

export default UserDetails;
