import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, UserService } from '../utils/types';

const useUserServices = () => {
  const fetchUserServices = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + '/users-services');
      return data;
    } catch (error : any) {
      throw error.response.data;
    }
  };

  return useQuery<{ services: UserService[] }, AxiosResponse<APIError>>(
    ['user-services'],
    fetchUserServices,
    {
      retry: false,
    }
  );
};

export default useUserServices;
