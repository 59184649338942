import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StrideAheadLogo from '../../assets/strideAheadLogoWhite.png';
import { useCurrentProfile } from '../../hooks/useProfile';
import Avatar from './Avatar';
import Flex from './Flex';
import GlobalSearch from './GlobalSearch';
import React from 'react';

const Navbar = () => {
  const { pathname } = useLocation();

  const { isSuccess } = useCurrentProfile();

  return (
    <Container align="center">
      <LogoContainer justify="center" align="center">
        <Link to="/">
          <img src={StrideAheadLogo} alt="Stride Ahead Logo" />
        </Link>
      </LogoContainer>
      {pathname === '/users' && <GlobalSearch />}
      {isSuccess && <Avatar />}
    </Container>
  );
};

export default Navbar;

const Container = styled(Flex)`
  width: 100%;
  height: 7rem;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 1rem ${({ theme }) => theme.colors.grey[100]};
  z-index: 1;
`;

const LogoContainer = styled(Flex)`
  height: 100%;
  width: 26rem;
`;
