import React, { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import styled from 'styled-components';
import useOutsideClick from '../../hooks/useOutsideClick';
import { DropdownItem, DropdownMenu } from './DropdownMenu';
import Flex from './Flex';

type selectProps = {
  width?: string;
  value: string | number;
};

const Select: React.FC<selectProps> = ({
  value,
  width = 'fit-content',
  children,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => setShowMenu(false));

  return (
    <Container
      align="center"
      justify="space-between"
      width={width}
      onClick={() => setShowMenu(!showMenu)}
      ref={ref}
    >
      <span>{value}</span>
      <FiChevronDown />
      {showMenu && (
        <StyledDropdownMenu
          column
          top="120%"
          left="-0.5rem"
          onClick={() => setShowMenu(!showMenu)}
        >
          {children}
        </StyledDropdownMenu>
      )}
    </Container>
  );
};

export default Select;

const Container = styled(Flex)<{ width: string }>`
  position: relative;
  width: ${(props) => props.width};
  cursor: pointer;

  svg {
    margin-left: 0.5rem;
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  width: fit-content;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 0 4px ${(props) => props.theme.colors.grey[200]};
`;

export const Option = styled(DropdownItem)`
  padding: 1rem 1rem;
  font-size: 14px;
`;
