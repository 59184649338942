import { FC, FocusEvent, InputHTMLAttributes, useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import { shake } from '../../styles/animations';
import Flex from './Flex';
import { Text1Css } from './Typography';
import React from 'react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  isDefault?: boolean;
  handleDelete?: any;
}

const RoleInput: FC<IProps> = ({
  type,
  name,
  placeholder,
  required,
  autoFocus,
  error,
  value,
  onChange,
  handleDelete,
  className,
  style,
  onBlur,
  isDefault = false,
  ...rest
}) => {
  const [focused, setFocused] = useState(autoFocus || false);

  const handleOnBlurEvent = (event: FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <Container
      focused={focused}
      error={error}
      className={className}
      style={style}
    >
      <Flex align="center">
        <input
          type={type}
          name={name}
          id={name}
          required={required}
          placeholder={placeholder}
          value={value || ''}
          autoFocus={autoFocus}
          formNoValidate
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={handleOnBlurEvent}
          className={`${error && 'error'} ${focused && 'focused'}`}
          {...rest}
        />
        {handleDelete && <DeleteRoleButton onClick={handleDelete} />}
      </Flex>
      {isDefault && <DefaultTag>Default</DefaultTag>}
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

export default RoleInput;

const Container = styled.div<{ focused: boolean; error?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 2rem;
  width: fit-content;
  ${Text1Css}

  /* Shake if there is an error */
  ${({ error }) =>
    error &&
    css`
      animation: ${shake} 0.7s ease-out;
    `}


  input {
    max-width: 25rem;
    height: 6rem;
    padding: 2.2rem;
    margin-top: 1rem;
    outline: none;
    font-size: inherit;
    border: 2px solid ${({ theme }) => theme.colors.grey[300]};

    /* Focused state styles */
    &.focused {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.blue[500]};
      border-color: ${({ theme }) => theme.colors.blue[500]};
    }

    /* Error state styles */
    &.error {
      box-shadow: 0 0 2px ${({ theme }) => theme.colors.red};
      border-color: ${({ theme }) => theme.colors.red[500]};
    }

    border-radius: 0.6rem;
    color: ${({ theme }) => theme.colors.grey[500]};
    transition: 0.1s;
  }

  span.error {
    margin-top: 0.8rem;
    margin-left: 0.4rem;
    color: ${({ theme }) => theme.colors.red[500]};
  }
`;

const DefaultTag = styled.span`
  position: absolute;
  top: 3rem;
  right: 2rem;
  color: ${({ theme }) => theme.colors.grey[400]};
  font-weight: 500;
`;

const DeleteRoleButton = styled(RiCloseFill)`
  font-size: 3.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.red[400]};
  cursor: pointer;
`;
