import { ChangeEvent, FocusEvent, FormEvent, useState } from 'react';
import {
  APIError,
  ErrorType,
  FormData,
  FormFields,
  ValidationSchema,
} from '../utils/types';

const validateEntries = (
  schema: ValidationSchema,
  data: { key: FormFields; value: string }
): boolean | string => {
  const validatorForCurrentField = schema[data.key];

  if (validatorForCurrentField?.required && data.value === '') {
    return `${validatorForCurrentField.name} is required`;
  } else if (validatorForCurrentField?.regex) {
    const isValid = validatorForCurrentField?.regex.test(data.value);

    if (!isValid) {
      return validatorForCurrentField?.errorText as string;
    }
  }
  return false;
};

const useForm = ({
  actions: { onSubmit },
  validatorSchema,
  handleValidationOnBlur,
}: {
  actions: {
    onSubmit: any;
    onBlur?: any;
  };
  validatorSchema?: ValidationSchema;
  handleValidationOnBlur: boolean;
}) => {
  const [isFormActive, setFormActive] = useState(false);
  const [formData, setFormData] = useState<FormData>({});
  const [formSubmitResponse, setFormSubmitResponse] = useState<{
    status: 'success' | 'fail';
    message: string;
  }>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormActive(true);
    const fieldName = e.target.name as FormFields;

    setFormData({
      ...formData,
      [fieldName]: { value: e.target.value, error: '' },
    });
  };

  const parseFormData = (data: FormData) => {
    const serializedFormData: any = {};

    const keys = Object.keys(data) as FormFields[];

    keys.forEach((key) => {
      serializedFormData[key] = data[key]?.value;
    });

    return serializedFormData;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submitResponse = (await onSubmit(parseFormData(formData))) as
      | APIError[]
      | string
      | void;

    const localFormData = { ...formData };

    if (submitResponse) {
      if (typeof submitResponse === 'string') {
        setFormSubmitResponse({ status: 'success', message: submitResponse });
      } else {
        submitResponse.forEach((error) => {
          const key = Object.keys(error)[0] as ErrorType;
          const value = error[key] as string;

          if (key === 'form_level_error') {
            setFormSubmitResponse({ status: 'fail', message: value });
          } else {
            const fieldData = localFormData[key];

            if (fieldData) {
              localFormData[key] = { ...fieldData, error: value };
            }
          }
        });
      }
      setFormData(localFormData);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (isFormActive && handleValidationOnBlur && validatorSchema) {
      const key = e.target.name as FormFields;
      const value = e.target.value;

      const isErrorOrValid = validateEntries(validatorSchema, { key, value });

      if (typeof isErrorOrValid === 'string') {
        setFormData({
          ...formData,
          [key]: { ...formData[key], error: isErrorOrValid },
        });
      }
    }
  };

  return {
    formData,
    formSubmitResponse,
    setFormData,
    handleChange,
    handleBlur,
    handleSubmit,
    parseFormData,
  };
};

export default useForm;
