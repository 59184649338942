import axios from 'axios';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useForm from '../../hooks/useForm';
import { fadeIn } from '../../styles/animations';
import {
  API_ENDPOINT,
  REGISTER_GOOGLE_FORM_VALIDATION,
} from '../../utils/constants';
import { APIError, FetchResponse, FormFields } from '../../utils/types';
import Button from '../components/Button';
import Flex from '../components/Flex';
import FormStatus from '../components/FormStatus';
import Input from '../components/Input';
import Separator from '../components/Separator';
import { Heading1, Text1Css } from '../components/Typography';
import React from "react";
import Cookies from 'js-cookie';

const RegisterWithGoogle = () => {
  const history = useHistory<{
    profile: {
      email: string;
      first_name: string;
      last_name: string;
    };
    tokenId?: string;
  }>();

  const [isLoading, setLoading] = useState(false);

  const urlSearchParams = new URLSearchParams(history.location.search);

  const redirectUrl = urlSearchParams.get('redirect_to') || Cookies.get('redirect_to');

  let url = new URL(`${API_ENDPOINT}/google-sign-up`);
  url.search = urlSearchParams.toString();
  const actionOnSubmit = async (
    data: { [key in FormFields]?: string }
  ): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      await axios.post<FetchResponse<{ message: string }>>(
        url.href,
        {
          ...data,
          google_auth_token: history.location.state.tokenId,
        }
      );

      delete history.location.state.tokenId;

      setLoading(false);

      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        history.push('/');
      }
    } catch (error : any) {
      setLoading(false);

      if (error.response.status === 401) {
        if (redirectUrl) {
          return history.push('/register?redirect_to=' + encodeURIComponent(redirectUrl));
        } else {
          return history.push('/register');
        }
      }

      const errors = error.response.data.error as APIError[];
      return errors;
    }
  };

  const {
    formData,
    setFormData,
    formSubmitResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: REGISTER_GOOGLE_FORM_VALIDATION,
    handleValidationOnBlur: true,
  });

  useEffect(() => {
    if (!history.location.state?.tokenId) {
      history.push('/login');
    } else {
      const { first_name, last_name, email } = history.location.state.profile;
      setFormData((formData) => {
        return {
          ...formData,
          first_name: { value: first_name, error: '' },
          last_name: { value: last_name, error: '' },
          email: { value: email, error: '' },
        };
      });
    }
  }, [setFormData, history]);

  const isButtonDisabled = () => {
    return !(
      formData.phone_no?.value !== '' &&
      formData.phone_no?.error === '' &&
      !isLoading
    );
  };

  return (
    <Container>
      <Heading1>Complete Your Profile</Heading1>
      <Description>
        For the purpose of industry regulations, we need your details
      </Description>
      <Separator />
      <form onSubmit={handleSubmit}>
        <Input
          label="Phone number"
          name="phone_no"
          type="phone"
          value={formData.phone_no?.value}
          error={formData.phone_no?.error}
          placeholder="Enter phone number"
          autoFocus
          required
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Address"
          name="address"
          type="text"
          value={formData.address?.value}
          error={formData.address?.error}
          placeholder="Enter your address"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Flex>
          <Input
            label="Zip Code"
            name="zip_code"
            value={formData.zip_code?.value}
            error={formData.zip_code?.error}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            placeholder="Enter zip code"
            style={{ marginRight: '2rem' }}
          />
          <Input
            label="Country"
            name="country"
            type="text"
            value={formData.country?.value}
            error={formData.country?.error}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter country"
          />
        </Flex>
        <StyledButton
          type="submit"
          disabled={isButtonDisabled()}
          isLoading={isLoading}
        >
          Register Now
        </StyledButton>
      </form>
      {formSubmitResponse && (
        <FormStatus
          align="center"
          className={formSubmitResponse.status}
          style={{ marginTop: '2rem' }}
        >
          {formSubmitResponse.message}
        </FormStatus>
      )}
    </Container>
  );
};

export default RegisterWithGoogle;

const Container = styled.div`
  width: 45rem;
  animation: ${fadeIn} 0.5s;
`;

const Description = styled.h2`
  ${Text1Css}
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.grey[300]};
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
