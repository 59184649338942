import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import useForm from '../../../hooks/useForm';
import useServices from '../../../hooks/useServices';
import { slideDown } from '../../../styles/animations';
import {
  ADD_SERVICE_VALIDATION_SCHEMA,
  API_ENDPOINT,
} from '../../../utils/constants';
import {
  APIError,
  FormData,
  FetchResponse,
  FormFields,
} from '../../../utils/types';
import Button from '../Button';
import Flex from '../Flex';
import Input from '../Input';
import RoleInput from '../RoleInput';
import { Heading2, Text1Css } from '../Typography';

const EditService: FC<{ serviceId: number; onClose: any }> = ({
  serviceId,
  onClose,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [defaultRole, setDefaultRole] = useState({ value: '', error: '' });
  const [roles, setRoles] = useState<{ value: string; error: string }[]>([]);

  const { data: services } = useServices();

  const queryClient = useQueryClient();

  const actionOnSubmit = async (
    data: { [key in FormFields]?: string | string[] }
  ): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      const postData = { ...data };

      postData.default_user_role = defaultRole.value;
      postData.possible_user_roles = roles.map((eachRole) => eachRole.value);

      await axios.patch<FetchResponse<{ message: string }>>(
        `${API_ENDPOINT}/services/${serviceId}`,
        postData
      );

      setLoading(false);
      queryClient.invalidateQueries(['services']);
      onClose();
    } catch (error : any) {
      setLoading(false);
      const errors = error.response.data.error as APIError[];
      return errors;
    }
  };

  const handleRoleValueChange = (event: any, key: number) => {
    const updatedRoles = [...roles];
    updatedRoles[key].value = event.target.value;
    setRoles(updatedRoles);
  };

  const deleteRole = (key: number) => {
    const updatedRoles = roles.filter((_, index) => index !== key);
    setRoles(updatedRoles);
  };

  const {
    formData,
    setFormData,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: ADD_SERVICE_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  const isButtonDisabled = () => {
    return !(
      formData.service_id?.value !== '' &&
      formData.service_id?.error === '' &&
      formData.service_name?.value !== '' &&
      formData.service_name?.error === '' &&
      defaultRole?.value !== '' &&
      defaultRole?.error === '' &&
      !isLoading
    );
  };

  useEffect(() => {
    const parseAndSetFormData = () => {
      const parsedFormData: FormData = {};

      type ServiceFields = 'service_name' | 'service_id' | 'service_url';

      if (services) {
        const currentService = services.filter(({ id }) => id === serviceId)[0];

        const keys = Object.keys(currentService) as ServiceFields[];
        keys.forEach((key) => {
          parsedFormData[key] = { error: '', value: currentService[key] };
        });

        const localUserRoles: { value: string; error: string }[] = [];

        currentService.possible_user_roles.forEach((role) => {
          localUserRoles.push({ error: '', value: role });
        });

        setDefaultRole({ error: '', value: currentService.default_user_role });
        setRoles(localUserRoles);
      }

      setFormData(parsedFormData);
    };

    parseAndSetFormData();
  }, [serviceId, services, setFormData]);

  return (
    <Container column>
      <Heading2>Edit Service</Heading2>
      <form onSubmit={handleSubmit}>
        <Flex>
          <Input
            label="Name"
            name="service_name"
            type="text"
            style={{ marginRight: '2rem' }}
            value={formData.service_name?.value}
            error={formData.service_name?.error}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
          />
          <Input
            label="Service ID"
            name="service_id"
            type="text"
            value={formData.service_id?.value}
            error={formData.service_id?.error}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Flex>
        <Input
          label="Service URL"
          name="service_url"
          type="url"
          value={formData.service_url?.value}
          error={formData.service_url?.error}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <RolesContainer column>
          <Flex justify="space-between">
            <Label htmlFor="roles">User Roles</Label>
            <AddRoleButton
              type="button"
              onClick={() => setRoles([...roles, { value: '', error: '' }])}
            >
              + Add
            </AddRoleButton>
          </Flex>
          <RoleInput
            name="default_role"
            type="text"
            value={defaultRole.value}
            isDefault
            onChange={(e) =>
              setDefaultRole({ ...defaultRole, value: e.target.value })
            }
          />
          {roles.map((eachRole, index) => (
            <Flex>
              <RoleInput
                key={index}
                name="role"
                type="text"
                value={eachRole.value}
                onChange={(e) => handleRoleValueChange(e, index)}
                handleDelete={() => deleteRole(index)}
              />
            </Flex>
          ))}
        </RolesContainer>
        <Flex>
          <CancelButton type="button" onClick={onClose}>
            Cancel
          </CancelButton>
          <UpdateButton
            type="submit"
            isLoading={isLoading}
            disabled={isButtonDisabled()}
          >
            Update
          </UpdateButton>
        </Flex>
      </form>
    </Container>
  );
};

export default EditService;

const Container = styled(Flex)`
  width: 60rem;
  height: fit-content;
  padding: 4rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;

  form {
    margin-top: 2rem;
  }
`;

const CancelButton = styled(Button)`
  width: 10rem;
  height: 5rem;
  margin-right: 1rem;
  margin-left: auto;
  background-color: #fff;
  color: ${(props) => props.theme.colors.grey[500]};
`;

const UpdateButton = styled(Button)`
  width: 10rem;
  height: 5rem;
`;

const RolesContainer = styled(Flex)`
  width: fit-content;
  max-width: 25rem;
`;

const AddRoleButton = styled.button`
  ${Text1Css}
  outline: none;
  border: none;
  background: none;
  font-weight: 600;
  color: ${(props) => props.theme.colors.green[400]};
  cursor: pointer;
`;

const Label = styled.label`
  ${Text1Css};
  color: ${({ theme }) => theme.colors.grey[300]};
`;
