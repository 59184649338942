import styled, { css } from 'styled-components';

const HeadingCss = css`
  font-family: 'Poppins';
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey[500]};
`;

export const Heading1 = styled.h1`
  ${HeadingCss}
  font-size: 3rem;
`;

export const Heading2 = styled.h2`
  ${HeadingCss}
  font-size: 2.6rem;
`;

export const Heading3 = styled.h3`
  ${HeadingCss}
  font-size: 2rem;
`;

export const Text1Css = css`
  font-size: 1.6rem;
  font-weight: 400;
`;

export const Text2Css = css`
  font-size: 1.4rem;
  font-weight: 400;
`;
