import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Flex from '../components/Flex';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import AdminHome from './AdminHome';
import ChangePassword from './ChangePassword';
import EditProfile from './EditProfile';
import ServicesList from './ServicesList';
import UserDetails from './UserDetails';
import UsersList from './UsersList';

const AdminDashboard = () => {
  return (
    <Container>
      <Navbar />
      <Sidebar />
      <Content>
        <Switch>
          <Route exact path="/" component={AdminHome} />
          <Route exact path="/users" component={UsersList} />
          <Route path="/users/:userId" component={UserDetails} />
          <Route path="/services" component={ServicesList} />
          <Route exact path="/account" component={EditProfile} />
          <Route path="/account/password" component={ChangePassword} />
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

export default AdminDashboard;

const Container = styled(Flex)`
  height: 100vh;
`;

const Content = styled(Flex)`
  width: calc(100% - 26rem);
  margin-top: 7rem;
  margin-left: 26rem;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.grey[100]};
`;
