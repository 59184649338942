import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import useForm from '../../../hooks/useForm';
import { useUserProfile } from '../../../hooks/useProfile';
import { slideDown } from '../../../styles/animations';
import {
  API_ENDPOINT,
  EDIT_PROFILE_FORM_VALIDATION_SCHEMA,
} from '../../../utils/constants';
import { APIError, FetchResponse, FormData } from '../../../utils/types';
import Button from '../../components/Button';
import Flex from '../../components/Flex';
import FormStatus from '../../components/FormStatus';
import Input from '../../components/Input';
import React from 'react';

const EditUserProfile: FC<{ userId: string; onClose: any }> = ({
  userId,
  onClose,
}) => {
  const [isLoading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const { data: userProfile } = useUserProfile(userId);

  const actionOnSubmit = async (data: any): Promise<APIError[] | string> => {
    setLoading(true);
    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.patch<FetchResponse<{ message: string }>>(
        `${API_ENDPOINT}/update-user-profile/${userId}`,
        data
      );

      setLoading(false);

      queryClient.invalidateQueries(['profile', userId]);
      queryClient.invalidateQueries(['users']);
      queryClient.invalidateQueries(['user', userId]);

      return message;
    } catch (error : any) {
      setLoading(false);
      const errors = error.response.data.error as APIError[];
      return errors;
    }
  };

  const {
    formData,
    formSubmitResponse,
    setFormData,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: EDIT_PROFILE_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  useEffect(() => {
    const parseAndSetFormData = () => {
      const parsedFormData: FormData = {};

      type ProfileFields =
        | 'first_name'
        | 'last_name'
        | 'phone_no'
        | 'address'
        | 'zip_code'
        | 'country';

      if (userProfile) {
        const keys = Object.keys(userProfile) as ProfileFields[];
        keys.forEach((key) => {
          parsedFormData[key] = { error: '', value: userProfile[key] };
        });
      }

      setFormData(parsedFormData);
    };

    parseAndSetFormData();
  }, [userProfile, setFormData]);

  const isButtonDisabled = () => {
    return !(
      formData.first_name?.value !== '' &&
      formData.first_name?.error === '' &&
      formData.last_name?.value !== '' &&
      formData.last_name?.error === '' &&
      formData.phone_no?.value !== '' &&
      formData.phone_no?.error === '' &&
      formData.address?.value !== '' &&
      formData.address?.error === ''
    );
  };

  return (
    <Container>
      <Header align="center" justify="space-between">
        Edit Profile
        <FaTimes onClick={onClose} />
      </Header>
      <form onSubmit={handleSubmit}>
        <Flex>
          <Input
            label="First Name"
            name="first_name"
            type="text"
            value={formData.first_name?.value}
            error={formData.first_name?.error}
            placeholder="Enter first name"
            required
            autoFocus
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ marginRight: '2rem' }}
          />
          <Input
            label="Last Name"
            name="last_name"
            type="text"
            value={formData.last_name?.value}
            error={formData.last_name?.error}
            placeholder="Enter last name"
            required
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Flex>
        <Input
          label="Phone number"
          name="phone_no"
          type="phone"
          value={formData.phone_no?.value}
          error={formData.phone_no?.error}
          placeholder="Enter phone number"
          required
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="Email Address"
          name="email"
          type="email"
          value={formData.email?.value}
          error={formData.email?.error}
          placeholder="Enter email address"
          required
          onChange={handleChange}
          onBlur={handleBlur}
          showSuccessIndicator
        />
        <Input
          label="Address"
          name="address"
          type="text"
          value={formData.address?.value}
          error={formData.address?.error}
          placeholder="Enter your address"
          required
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Flex>
          <Input
            label="Zip Code"
            name="zip_code"
            value={formData.zip_code?.value}
            error={formData.zip_code?.error}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            placeholder="Enter zip code"
            style={{ marginRight: '2rem' }}
          />
          <Input
            label="Country"
            name="country"
            type="text"
            value={formData.country?.value}
            error={formData.country?.error}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter country"
          />
        </Flex>
        <StyledButton
          type="submit"
          disabled={isButtonDisabled()}
          isLoading={isLoading}
        >
          Save Information
        </StyledButton>
      </form>
      {formSubmitResponse && (
        <FormStatus
          align="center"
          className={formSubmitResponse.status}
          style={{ margin: '0 4rem' }}
        >
          {formSubmitResponse.message}
        </FormStatus>
      )}
    </Container>
  );
};

export default EditUserProfile;

const Container = styled.div`
  width: 50rem;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 4px;
  padding-bottom: 2rem;
  animation: ${slideDown} 0.4s ease-out;

  form {
    padding: 2rem 4rem;
  }
`;

const Header = styled(Flex)`
  font-size: 2.2rem;
  font-weight: 500;
  padding: 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};

  svg {
    cursor: pointer;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
