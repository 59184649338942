import React from 'react';
import styled from 'styled-components';
import useUserServices from '../../hooks/useUserServices';
import Flex from '../components/Flex';
import { ServiceCard } from '../components/ServiceCard';

const UserServices = () => {
  const { data } = useUserServices();

  return (
    <Container>
      {data?.services.map((eachService) => (
        <ServiceCard href={eachService.service_url}>
          {eachService.service_name}
        </ServiceCard>
      ))}
    </Container>
  );
};

export default UserServices;

const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  padding: 4rem;
`;
