const colors = {
  red: {
    500: '#EB5757',
    400: '#ED6262',
    300: '#F7B7B7',
  },
  orange: '#F2994A',
  yellow: {
    500: '#EFCF1B',
  },
  green: {
    500: '#5BC044',
    400: '#27AE60',
    300: '#C3F3D7',
    200: '#D7EBD3',
  },
  blue: {
    500: '#3f52fd',
  },
  grey: {
    600: '#333333',
    500: '#4F4F4F',
    400: '#828282',
    300: '#BDBDBD',
    200: '#E0E0E0',
    100: '#F2F2F2',
  },
  white: '#ffffff',
};

export const light = {
  colors,
};
