import { Route, Switch, useHistory } from 'react-router-dom';
import { useCurrentProfile } from '../../hooks/useProfile';
import Dashboard from './Dashboard';
import React from 'react';

const AuthenticatedApp = () => {
  const { isLoading, isError } = useCurrentProfile();
  const history = useHistory();

  const renderAppComponent = () => {
    if (isLoading) {
      return <div>App Loading...</div>;
    } else if (isError) {
      history.push('/login');
    } else {
      return (
        <Switch>
          <Route path="/" component={Dashboard} />
        </Switch>
      );
    }
  };

  return <>{renderAppComponent()}</>;
};

export default AuthenticatedApp;
