import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, Service } from '../utils/types';

const useServices = () => {
  const fetchServices = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + '/services');
      return data.services;
    } catch (error : any) {
      throw error.response.data;
    }
  };

  return useQuery<Service[], AxiosResponse<APIError>>(
    ['services'],
    fetchServices,
    {
      retry: false,
    }
  );
};

export default useServices;
