import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { API_ENDPOINT, PAGINATION_LIMIT } from "../utils/constants";
import {
  APIError,
  PaginationData,
  SearchData,
  UserSummary,
} from "../utils/types";

interface Props {
  page?: number;
  limit?: number;
  search?: string;
}

const useUsers = ({ page = 1, limit = PAGINATION_LIMIT, search }: Props) => {
  const fetchUsers = async ({ page, limit, search }: Props) => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + `/users`, {
        params: { page, limit, search },
      });
      return data;
    } catch (error : any) {
      throw error.response.data;
    }
  };

  return useQuery<
    {
      users: UserSummary[];
      pagination_data: PaginationData;
      search: SearchData;
    },
    AxiosResponse<APIError>
  >(["users", page, search], () => fetchUsers({ page, limit, search }), {
    retry: false,
  });
};

export default useUsers;
