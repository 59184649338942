import { ButtonHTMLAttributes, FC, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { spin } from '../../styles/animations';
import React from 'react';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
}

const Button: FC<IProps> = ({
  disabled,
  className,
  children,
  isLoading = false,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <StyledButton
      disabled={disabled || isLoading}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={`${className} ${!disabled && focused && 'focused'}`}
      {...rest}
    >
      {isLoading ? <Spinner /> : <>{children}</>}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  width: 100%;
  height: 6rem;
  outline: none;
  border: 2px solid transparent;
  background-color: ${({ theme }) => theme.colors.blue[500]};
  ${({ disabled }) =>
    disabled &&
    css`
      filter: opacity(60%);
    `}
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.6rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: 0.2s;

  &.focused {
    border-color: ${({ theme }) => theme.colors.grey[500]};
  }
`;

const Spinner = styled(FaSpinner)`
  font-size: 2.5rem;
  animation: ${spin} 0.5s linear infinite;
`;
