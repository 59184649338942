import { RiSearch2Line } from "react-icons/ri";
import styled from "styled-components";
import Flex from "./Flex";
import { Text1Css } from "./Typography";
import React from "react";

const GlobalSearch = () => {
  return (
    <Container align="center">
      <RiSearch2Line />
      <input type="search" name="global_search" placeholder="Global Search" />
    </Container>
  );
};

export default GlobalSearch;

const Container = styled(Flex)`
  height: 100%;
  flex-grow: 1;
  padding: 0 4rem;
  ${Text1Css}
  color: ${(props) => props.theme.colors.grey[500]};

  input {
    height: 80%;
    width: 100%;
    outline: none;
    border: none;
    font-size: inherit;
    margin-left: 1rem;
  }
`;
