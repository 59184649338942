import {FC, FocusEvent, InputHTMLAttributes, useState} from 'react';
import {FaCheckCircle} from 'react-icons/fa';
import styled, {css} from 'styled-components';
import {fadeIn, shake} from '../../styles/animations';
import {Text1Css} from './Typography';
import PhoneInput from "react-phone-input-2";
import React from 'react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    error?: string;
    country: string;
    showSuccessIndicator?: boolean;
}

const PhoneInput2: FC<IProps> = ({
                                     type,
                                     label,
                                     name,
                                     placeholder,
                                     required,
                                     autoFocus,
                                     error,
                                     value,
                                     onChange,
                                     country,
                                     className,
                                     style,
                                     onBlur,
                                     showSuccessIndicator = false,
                                     ...rest
                                 }) => {
    const [focused, setFocused] = useState(autoFocus || false);

    const handleOnBlurEvent = (event: FocusEvent<HTMLInputElement>) => {
        setFocused(false);
        if (onBlur) {
            onBlur(event);
        }
    };

    return (
        <Container
            focused={focused}
            error={error}
            className={className}
            style={style}
        >
            <label htmlFor={name}>
                {label}
                {required && ' *'}
            </label>
            <PhoneInput
                name={name}
                id={name}
                placeholder={placeholder}
                // @ts-ignore
                autoFocus={autoFocus}
                required={required}
                // @ts-ignore
                value={value || ""}
                country={country}
                formNoValidate
                // @ts-ignore
                onChange={onChange}
                onFocus={() => setFocused(true)}
                onBlur={handleOnBlurEvent}
                // @ts-ignore
                className={`${error && 'error'} ${focused && 'focused'}`}
                {...rest}
            />
            {showSuccessIndicator && !focused && value && value !== '' && !error && (
                <StyledCheck/>
            )}
            {error && <span className="error">{error}</span>}
        </Container>
    );
};

export default PhoneInput2;

const Container = styled.div<{ focused: boolean; error?: string }>`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 2rem;
  ${Text1Css}

  /* Shake if there is an error */
  ${({error}) =>
    error &&
    css`
      animation: ${shake} 0.7s ease-out;
    `}

  label {
    color: ${({theme}) => theme.colors.grey[300]};
  }
  
.react-tel-input{
  margin-top:1rem;
}  
.react-tel-input.form-control{
    padding-left:68px !important;
    
}
.flag-dropdown {
    border:none;
    border-radius:10px 0px 0px 10px;
    background:transparent !important;
    &::after{
        content:"";
        position:absolute;
        left:100%;
        top:17px;
        width:1px;
        height:24px;
        background:#BDBDBD;
    }
    
}
.selected-flag{
    width:50px;
    border-radius:10px 0px 0px 10px;
    &:hover{
        border-radius:10px 0px 0px 10px;
        background:transparent !important;
    }
}
.open{
    border-radius:5px !important;

}
.flag{
    margin:0px !important;
    left:50%;
    transform:translate(-50%,-50%);
}

.country-list{
    padding:5px 15px;
    }
    
.country{
    display: flex;
    align-items: end;
    }

  input {
    width: 100% !important;
    height: 6rem !important;
    padding:  10px 0px 10px 60px !important;
    margin-top: 1rem;
    outline: none;
    font-size: inherit;
    border: 2px solid ${({theme}) => theme.colors.grey[300]} !important;

    /* Focused state styles */
    &:focus {
      box-shadow: 0 0 2px ${({theme}) => theme.colors.blue[500]} !important;
      border-color: ${({theme}) => theme.colors.blue[500]} !important;
    }

    /* Error state styles */
    &:error {
      box-shadow: 0 0 2px ${({theme}) => theme.colors.red};
      border-color: ${({theme}) => theme.colors.red[500]};
    }

    border-radius: 0.6rem;
    color: ${({theme}) => theme.colors.grey[500]};
    transition: 0.1s;
  }

  span.error {
    margin-top: 0.8rem;
    margin-left: 0.4rem;
    color: ${({theme}) => theme.colors.red[500]};
  }
`;

const StyledCheck = styled(FaCheckCircle)`
  position: absolute;
  font-size: 2rem;
  right: 1.5rem;
  top: 5.5rem;
  color: ${({theme}) => theme.colors.blue[500]};
  animation: ${fadeIn} 0.5s;
`;

const StyledPhoneInput = styled(PhoneInput)`
    border:1.2px solid #88b9db;
    border-radius:10px;
    margin-top:8px;
.react-tel-input.form-control{
    padding-left:68px !important;
}
.flag-dropdown {
    border:none;
    border-radius:10px 0px 0px 10px;
    background:transparent !important;
    &::after{
        content:"";
        position:absolute;
        left:100%;
        top:9px;
        width:1px;
        height:24px;
        background:#88b9db;
    }
    
}
.selected-flag{
    width:50px;
    border-radius:10px 0px 0px 10px;
    &:hover{
        border-radius:10px 0px 0px 10px;
        background:transparent !important;
    }
}
.open{
    border-radius:10px !important;

}
.flag{
    margin:0px !important;
    left:50%;
    transform:translate(-50%,-50%);
}

.country-list{
    padding:5px 15px;
    }
    
.country{
    display: flex;
    align-items: end;
    }

input{  
    width:100% !important;
    height:50px !important;   
    padding:  10px 0px 10px 60px !important;
    font-weight: 600;
    font-size: 16px;
    color: #3f52fd;
    background: #ffffff;
    // border-radius: 8px;
    border:none !important;
   border-radius: 10px !important;
   
   @media(max-width:576px){
       height:42px !important;
    }
   
   &:focus{
   background: #E7F1F8;
   border: 1.2px solid #3f52fd;
   border-radius: 10px;
   outline: none;
   .flag-dropdown{
   background: #E7F1F8;
   }
 }
   }
  
`;
