import axios from 'axios';
import {FC, FocusEvent, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import styled from 'styled-components';
import useForm from '../../hooks/useForm';
import {fadeIn} from '../../styles/animations';
import {API_ENDPOINT} from '../../utils/constants';
import {APIError, FetchResponse, FormFields} from '../../utils/types';
import Button from '../components/Button';
import FormStatus from '../components/FormStatus';
import Input from '../components/Input';
import Separator from '../components/Separator';
import {Heading1, Text1Css} from '../components/Typography';
import React from "react";
import Input2 from "../components/Input2";

const EmailForm = () => {
    const [isLoading, setLoading] = useState(false);

    const actionOnSubmit = async (
        data: { [key in FormFields]?: string }
    ): Promise<APIError[] | string> => {
        setLoading(true);
        try {
            const {
                data: {
                    data: {message},
                },
            } = await axios.post<FetchResponse<{ message: string }>>(
                API_ENDPOINT + '/request-password-reset',
                data
            );

            setLoading(false);
            return message;
        } catch (error : any) {
            setLoading(false);
            const errors = error.response.data.error as APIError[];
            return errors;
        }
    };

    const {
        formData,
        formSubmitResponse,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm({
        actions: {onSubmit: actionOnSubmit},
        validatorSchema: {
            email: {
                name: 'Email address',
                required: true,
                regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                errorText: 'Please enter a valid email',
            },
        },
        handleValidationOnBlur: true,
    });

    const isButtonDisabled = () => {
        return !(formData.email?.value !== '' && formData.email?.error === '');
    };

    return (
        <>
            <RightBanner>
                <Heading>Reset your password</Heading>
                <Text>
                    Please enter the registered email. The password reset link will be sent
                    to this email address.
                </Text>
                <Separator/>
                <form onSubmit={handleSubmit}>
                    <StyleInput
                        label="Email address"
                        name="email"
                        type="email"
                        placeholder="Enter email address"
                        value={formData.email?.value}
                        error={formData.email?.error}
                        required
                        autoFocus
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <StyledButton
                        type="submit"
                        isLoading={isLoading}
                        disabled={isButtonDisabled()}
                    >
                        Send password reset link
                    </StyledButton>
                </form>
                {formSubmitResponse && (
                    <FormStatus
                        align="center"
                        className={formSubmitResponse.status}
                        style={{marginTop: '2rem'}}
                    >
                        {formSubmitResponse.message.replace('mail ID.', '')}
                        &nbsp;
                        {formSubmitResponse.status === 'success' && (
                            <a href={`mailto:${formData.email?.value}`}>mail ID.</a>
                        )}
                    </FormStatus>
                )}
            </RightBanner>
            <Foot>
                <Text>© 2022, Stride Ahead™</Text>
            </Foot>
        </>
    );
};

const NewPasswordForm: FC<{ token: string }> = ({token}) => {
    const [isLoading, setLoading] = useState(false);

    const actionOnSubmit = async (data: any): Promise<APIError[] | string> => {
        setLoading(true);
        try {
            delete data['confirm_password'];

            const {
                data: {
                    data: {message},
                },
            } = await axios.patch<FetchResponse<{ message: string }>>(
                `${API_ENDPOINT}/reset-password/${token}`,
                data
            );

            setLoading(false);
            return message;
        } catch (error : any) {
            setLoading(false);
            const errors = error.response.data.error as APIError[];
            return errors;
        }
    };

    const {
        formData,
        setFormData,
        formSubmitResponse,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm({
        actions: {onSubmit: actionOnSubmit},
        validatorSchema: {
            new_password: {
                name: 'New Password',
                required: true,
            },
            confirm_password: {
                name: 'Confirm Password',
                required: true,
            },
        },
        handleValidationOnBlur: true,
    });

    const handleConfirmPasswordBlur = (e: FocusEvent<HTMLInputElement>) => {
        const key = e.target.name as FormFields;
        const value = e.target.value;

        if (value !== formData.new_password?.value) {
            setFormData({
                ...formData,
                [key]: {
                    ...formData[key],
                    error: "Passwords don't match",
                },
            });
        }
    };

    const isButtonDisabled = () => {
        return !(
            formData.new_password?.value !== '' &&
            formData.new_password?.error === '' &&
            formData.confirm_password?.value !== '' &&
            formData.confirm_password?.error === ''
        );
    };

    return (
        <>
            <RightBanner>
                <Heading>Create new Password</Heading>
                <Text>Enter the new password</Text>
                <Separator/>
                <form onSubmit={handleSubmit}>
                    <StyleInput
                        label="New Password"
                        name="new_password"
                        type="password"
                        placeholder="Enter new password"
                        value={formData.new_password?.value}
                        error={formData.new_password?.error}
                        required
                        autoFocus
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <StyleInput
                        label="Confirm Password"
                        name="confirm_password"
                        type="password"
                        placeholder="Enter confirm password"
                        value={formData.confirm_password?.value}
                        error={formData.confirm_password?.error}
                        required
                        onChange={handleChange}
                        onBlur={handleConfirmPasswordBlur}
                    />
                    <StyledButton
                        type="submit"
                        isLoading={isLoading}
                        disabled={isButtonDisabled()}
                    >
                        Reset Password
                    </StyledButton>
                </form>

                {formSubmitResponse && (
                    <FormStatus
                        align="center"
                        className={formSubmitResponse.status}
                        style={{marginTop: '2rem'}}
                    >
                        {formSubmitResponse.message}
                        &nbsp;
                        {formSubmitResponse.status === 'success' && (
                            <Link to="/login">Login</Link>
                        )}
                    </FormStatus>
                )}

            </RightBanner>
            <Foot>
                <Text>© 2022, Stride Ahead™</Text>
            </Foot>
        </>
    );
};

const ResetPassword = () => {
    const {token} = useParams<{ token?: string }>();

    return <>{token ? <NewPasswordForm token={token}/> : <EmailForm/>}</>;
};

export default ResetPassword;

const Container = styled.div`
  width: 45rem;
  margin:0 auto;
  animation: ${fadeIn} 0.5s;
  @media(max-width:1024px){
   padding:40px 100px;
   width:100%;
  }
  @media(max-width:576px){
   padding: 0px;
   width:100%;
  }
`;
const RightBanner = styled.div`
  padding: 40px 100px;
  width:100%;
  @media(max-width: 576px){
    padding: 0px 0px;
  } 
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #073b5f;
  @media(max-width:576px){
    font-size: 20px;
    }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #93acbd;
  margin: 0;
  @media(max-width:576px){
    font-size: 12px;
    }
`;

const StyleInput = styled(Input2)`
  
`;

const Description = styled.h2`
  ${Text1Css}
  margin-top: 1rem;
  line-height: 1.6;
  color: ${(props) => props.theme.colors.grey[300]};
`;


const StyledButton = styled(Button)`
  background: #3f52fd;
  border-radius: 10px;
  margin-top: 2rem;
  @media(max-width:576px){
   margin-top:2rem;
   height:50px !important;
    }
`;

const Foot = styled.div`
  @media(min-width:576px){
   display:none;
   }
   @media(max-width:576px){
    margin-top:20px;
    text-align:center;
    display:block;
   }
   @media (min-width: 768px) and (max-width: 1024px){
    margin-top:40px;
    text-align:center;
    display:block;
  }
`;