import React from 'react';
import styled from 'styled-components';
import useUsersCount from '../../hooks/useUsersCount';
import { fadeIn } from '../../styles/animations';
import Flex from '../components/Flex';
import UserServices from './UserServices';

const AdminHome = () => {
  const { data: usersCount } = useUsersCount();

  return (
    <Container column>
      <UserCountContainer>
        <UserCountCard column align="center" justify="center">
          Registered Users
          <span className="count">
            {usersCount?.registered_user_count || '-'}
          </span>
        </UserCountCard>
        <UserCountCard column align="center" justify="center" color="green">
          Logged In Users
          <span className="count">
            {usersCount?.logged_in_user_count || '-'}
          </span>
        </UserCountCard>
      </UserCountContainer>
      <Header align="center">Services</Header>
      <UserServices />
    </Container>
  );
};

export default AdminHome;

const Container = styled(Flex)`
  width: 100%;
  animation: ${fadeIn} 0.4s;
`;

const UserCountContainer = styled(Flex)`
  padding: 4rem;
`;

const Header = styled(Flex)`
  height: 6rem;
  width: 100%;
  padding: 2rem 4rem;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[200]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  background-color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
`;

const UserCountCard = styled(Flex)`
  width: 30rem;
  height: 16rem;
  background-color: ${(props) =>
    props.color
      ? props.theme.colors[props.color][400]
      : props.theme.colors.blue[500]};
  border-radius: 5px;
  font-size: 2.4rem;
  font-weight: 500;
  color: #fff;
  margin-right: 4rem;

  .count {
    font-size: 4rem;
    margin-top: 2rem;
  }
`;
