import axios from 'axios';
import { useState } from 'react';
import styled from 'styled-components';
import useForm from '../../hooks/useForm';
import { fadeIn } from '../../styles/animations';
import {
  API_ENDPOINT,
  CHANGE_PASSWORD_VALIDATION_SCHEMA,
} from '../../utils/constants';
import { APIError, FetchResponse, FormFields } from '../../utils/types';
import Button from '../components/Button';
import FormStatus from '../components/FormStatus';
import Input from '../components/Input';
import Separator from '../components/Separator';
import { Heading1 } from '../components/Typography';
import React from 'react';

const ChangePassword = () => {
  const [isLoading, setLoading] = useState(false);

  const actionOnSubmit = async (
    data: { [key in FormFields]?: string }
  ): Promise<APIError[] | string> => {
    setLoading(true);
    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.patch<FetchResponse<{ message: string }>>(
        API_ENDPOINT + '/change-password',
        data
      );

      setLoading(false);
      return message;
    } catch (error : any) {
      setLoading(false);
      const errors = error.response.data.error as APIError[];
      return errors;
    }

    // TODO: Implement this
  };

  const {
    formData,
    formSubmitResponse,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });

  const isButtonDisabled = () => {
    return !(
      formData.old_password?.value !== '' &&
      formData.old_password?.error === '' &&
      formData.new_password?.value !== '' &&
      formData.new_password?.error === '' &&
      !isLoading
    );
  };

  return (
    <Container>
      <Heading1 style={{ textAlign: 'center' }}>Change Password</Heading1>
      <Separator />
      <form onSubmit={handleSubmit}>
        <Input
          label="Old Password"
          name="old_password"
          type="password"
          placeholder="Enter old password"
          value={formData.old_password?.value}
          error={formData.old_password?.error}
          required
          autoFocus
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Input
          label="New Password"
          name="new_password"
          type="password"
          placeholder="Enter new password"
          value={formData.new_password?.value}
          error={formData.new_password?.error}
          required
          onChange={handleChange}
          onBlur={handleBlur}
          showSuccessIndicator={false}
        />
        <StyledButton
          type="submit"
          disabled={isButtonDisabled()}
          isLoading={isLoading}
        >
          Save Password
        </StyledButton>
      </form>
      {formSubmitResponse && (
        <FormStatus align="center" className={formSubmitResponse.status}>
          {formSubmitResponse.message}
        </FormStatus>
      )}
    </Container>
  );
};

export default ChangePassword;

const Container = styled.div`
  width: 45rem;
  margin: 0 auto;
  padding: 4rem 0;
  animation: ${fadeIn} 0.5s;
`;

const StyledButton = styled(Button)`
  margin: 2rem 0;
`;
