import { FC } from 'react';
import { useCurrentProfile } from '../../hooks/useProfile';
import { useHistory } from 'react-router-dom';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';
import React from 'react';
import Cookies from 'js-cookie';

const Dashboard: FC = () => {
  const { data: currentProfile } = useCurrentProfile();
  const history = useHistory();

  const urlSearchParams = new URLSearchParams(history.location.search);

  const redirectUrl = urlSearchParams.get('redirect_to') || Cookies.get('redirect_to');

  if (redirectUrl) {
    window.location.href = decodeURIComponent(redirectUrl);
  }

  const renderDashboard = () => {
    if (currentProfile?.user_role === 'user') {
      return <UserDashboard />;
    } else if (currentProfile?.user_role === 'admin') {
      return <AdminDashboard />;
    }
  };

  return <>{renderDashboard()}</>;
};

export default Dashboard;
