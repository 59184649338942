import { FC } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AuthenticatedApp from "../pages/AuthenticatedApp";
import EmailVerification from "../pages/EmailVerification";
import ErrorFallback from "../pages/ErrorFallback";
import UnauthenticatedApp from "../pages/UnauthenticatedApp";
import React from "react";

const RoutesContainer: FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/:authPath(login|register|email_otp_verification|register_otp_verification|email_verification|register_google|reset_password|linkedin)"
          component={UnauthenticatedApp}
        />
        <Route
          path="/:appPaths(|account|users|services)"
          component={AuthenticatedApp}
        />
        <Route path="/verify_email/:token?" component={EmailVerification} />
        <Route path="*">
          <ErrorFallback error="404" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default RoutesContainer;
