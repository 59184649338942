import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, CurrentUserProfile, UserProfile } from '../utils/types';

export const useCurrentProfile = () => {
  const fetchCurrentProfile = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + '/me');
      return data;
    } catch (error : any) {
      throw error?.response?.data;
    }
  };

  return useQuery<CurrentUserProfile, AxiosResponse<APIError>>(
    ['profile', 'current'],
    fetchCurrentProfile,
    {
      retry: false,
    }
  );
};

export const useUserProfile = (userId: string) => {
  const fetchUserProfile = async (userId: string) => {
    try {
      const {
        data: { data },
      } = await axios.get(`${API_ENDPOINT}/get-user-profile/${userId}`);
      return data;
    } catch (error : any) {
      throw error.response.data;
    }
  };

  return useQuery<UserProfile, AxiosResponse<APIError>>(
    ['profile', userId],
    () => fetchUserProfile(userId),
    {
      retry: false,
    }
  );
};
