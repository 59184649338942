import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Error404 from '../../assets/errors/404.png';
import Error500 from '../../assets/errors/500.png';
import ErrorLinkExpired from '../../assets/errors/link_expired.png';
import { AppError } from '../../utils/types';
import Button from '../components/Button';
import Flex from '../components/Flex';
import Navbar from '../components/Navbar';
import { Heading1, Text1Css } from '../components/Typography';
import React from 'react';

const ErrorFallback: FC<{ error: AppError; requestAnotherLink?: any }> = ({
  error,
  requestAnotherLink,
}) => {
  const history = useHistory();

  const renderErrorImage = () => {
    switch (error) {
      case '404':
        return <ErrorImage src={Error404} />;
      case 'password_link_expired':
      case 'register_link_expired':
        return <ErrorImage src={ErrorLinkExpired} />;
      case '500':
      default:
        return <ErrorImage src={Error500} />;
    }
  };

  const getErrorTitle = () => {
    switch (error) {
      case 'password_link_expired':
        return 'Password reset link expired';
      case 'register_link_expired':
        return 'Register link expired';
      case '500':
      case '404':
      default:
        return 'Oops!';
    }
  };

  const getErrorDescription = () => {
    switch (error) {
      case '404':
        return 'We can’t seem to find the page you are looking for';
      case 'password_link_expired':
        return 'Password reset link expires in every 24 hours and can only be used once. You can create one by clicking on Request button too. ';
      case 'register_link_expired':
        return 'Register validation link expires in every 24 hours and can only be used one. You can create one by clicking on Request button too.';
      case '500':
      default:
        return 'We’re not exactly sure what happened, but our servers say something is wrong';
    }
  };

  const getButtonText = () => {
    switch (error) {
      case 'password_link_expired':
      case 'register_link_expired':
        return 'Request another link';
      case '404':
      case '500':
      default:
        return 'Go to Homepage';
    }
  };

  const clickHandler = () => {
    switch (error) {
      case 'password_link_expired': {
        history.push('/reset_password');
        break;
      }
      case 'register_link_expired': {
        requestAnotherLink();
        break;
      }
      case '404':
      case '500':
      default: {
        history.push('/');
        break;
      }
    }
  };

  return (
    <Container column>
      <Navbar />
      <StyledFlex column align="center">
        {renderErrorImage()}
        <Title>{getErrorTitle()}</Title>
        <Description>{getErrorDescription()}</Description>
        <ActionButton onClick={clickHandler}>{getButtonText()}</ActionButton>
      </StyledFlex>
    </Container>
  );
};

export default ErrorFallback;

const Container = styled(Flex)`
  min-height: 100%;
`;

const StyledFlex = styled(Flex)`
  padding-top: 7rem;
`;

const ErrorImage = styled.img`
  width: 50rem;
  margin: 4rem 0;
`;

const Title = styled(Heading1)`
  color: ${({ theme }) => theme.colors.red[500]};
`;

const Description = styled.p`
  ${Text1Css}
  margin: 2rem 0;
`;

const ActionButton = styled(Button)`
  width: 30rem;
`;
