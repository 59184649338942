import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import App from './App';
import './index.css';
import { light } from './styles/theme';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-WNL5HL8'
}

TagManager.initialize(tagManagerArgs);

axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={light}>
      <QueryClientProvider client={new QueryClient()}>
        <App />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
