import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import EmailVerificationSuccess from '../../assets/email_verification_success.png';
import StrideLogoLoadingGif from '../../assets/stride_logo_loading.gif';
import { API_ENDPOINT } from '../../utils/constants';
import { AppError } from '../../utils/types';
import Button from '../components/Button';
import Flex from '../components/Flex';
import FormStatus from '../components/FormStatus';
import Modal from '../components/Modal';
import Navbar from '../components/Navbar';
import { Heading1, Text1Css } from '../components/Typography';
import ErrorFallback from './ErrorFallback';
import Cookies from 'js-cookie';

const EmailVerification: FC<{ error: AppError }> = ({ error }) => {
  const [isLoading, setLoading] = useState(true);
  const [isVerified, setVerified] = useState<boolean>();
  const [formResponse, setFormResponse] = useState<{
    status: string;
    message: string;
  }>();
  const [verifiedEmail, setVerifiedEmail] = useState<string>();

  const history = useHistory();

  const urlSearchParams = new URLSearchParams(history.location.search);

  const redirectUrl = urlSearchParams.get('redirect_to') || Cookies.get('redirect_to');

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const {
          data: {
            data: { email },
          },
        } = await axios.put(`${API_ENDPOINT}/verify-email/${token}`);

        setVerifiedEmail(email);
        setLoading(false);
        setVerified(true);

        if (redirectUrl) {
          history.push(`/login?redirect_to=${encodeURIComponent(redirectUrl)}`)
        }
      } catch (error) {
        setLoading(false);
        setVerified(false);
      }
    };

    if (token) {
      verifyEmail();
    }
  }, [token, history, redirectUrl]);

  const requestAnotherLink = async () => {
    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.post(
        `${API_ENDPOINT}/request-verification-email/${token}`
      );

      setFormResponse({ status: 'success', message });
    } catch (error:any) {
      setFormResponse({
        status: 'fail',
        message: error.response.data.error[0]['form_level_error'],
      });
    }
  };

  if (!isLoading && !isVerified) {
    return (
      <>
        <ErrorFallback
          error="register_link_expired"
          requestAnotherLink={requestAnotherLink}
        />
        {formResponse && (
          <StyledFormStatus className={formResponse.status}>
            {formResponse.message}
          </StyledFormStatus>
        )}
      </>
    );
  }

  return (
    <Container column>
      <Navbar />
      {isLoading ? (
        <Modal>
          <img src={StrideLogoLoadingGif} alt="Loading" />
        </Modal>
      ) : (
        <StyledFlex column align="center">
          <ErrorImage src={EmailVerificationSuccess} />
          <Title>Verified</Title>
          <Description>
            Voila! You have successfully verified your account.
          </Description>
          <ActionButton
            onClick={() => history.push(`/login?email=${verifiedEmail}`)}
          >
            Sign In Now
          </ActionButton>
        </StyledFlex>
      )}
    </Container>
  );
};

export default EmailVerification;

const Container = styled(Flex)`
  min-height: 100%;
`;

const StyledFlex = styled(Flex)`
  padding-top: 7rem;
`;

const ErrorImage = styled.img`
  width: 50rem;
  margin: 4rem 0;
`;

const Title = styled(Heading1)`
  color: ${({ theme }) => theme.colors.red[500]};
`;

const Description = styled.p`
  ${Text1Css}
  margin: 2rem 0;
`;

const ActionButton = styled(Button)`
  width: 30rem;
`;

const StyledFormStatus = styled(FormStatus)`
  margin: auto;
  margin-top: 2rem;
  max-width: 30rem;
`;
