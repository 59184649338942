import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import Flex from "../components/Flex";
import Button from "../components/Button";
import {Text1Css} from "../components/Typography";
import Email_img from "../../assets/svg/email_verification.svg"
import {useLocation, useHistory} from "react-router-dom";
import axios from "axios";
import {FetchResponse} from "../../utils/types";
import {API_ENDPOINT} from "../../utils/constants";

function EmailVerification2() {
    const location = useLocation<any>();
    const history = useHistory()
    const [alertStyle, setAlertStyle] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [messages, setMessages] = useState<any>({type: "", message: ""});
    const email = location.state?.emailId;
    const name = location.state?.name;

    async function apiAuth() {
        try {
            const resp = await axios.get<FetchResponse<{ message: string }>>(
                API_ENDPOINT + '/me',
            );
            if (resp?.data?.success) {
                history.push({
                    pathname: "/",
                })
            }
        } catch (err) {
            console.log("error")
        }
    }

    useEffect(()=>{
        apiAuth()
    },[])

    const re_send_email = async ()=>{
        const resp = await axios.post<FetchResponse<{ message: string }>>(
            API_ENDPOINT + '/request-verification-email',
            {email}
        );
        console.log(resp.data)
        if (resp?.data?.success) {
           setMessages({
               type:'success',
               message: `${resp?.data?.message}` || `${resp?.data?.data?.message}`
           })
        }else {
            setMessages({
                type:'error',
                message: `${resp?.data?.message}` || `${resp?.data?.data?.message}`
            })
        }
    }
    useEffect(() => {
        messages.message ? setShowAlert(true) : setShowAlert(false);
    }, [messages]);

    useEffect(() => {
        messages?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
    }, [messages]);

    return (
        <RightBanner>
            <Text>Hey, {name} </Text>
            <Heading>Verify your Email</Heading>
            <Text style={{marginBottom:"30px"}}>
                You are few steps away from your unlocking your dream career
            </Text>

            {showAlert && (
                <div
                    className={
                        alertStyle ? "alert alert-success " : "alert alert-danger"
                    }
                    role="alert"
                >
                    {messages?.message}
                </div>
            )}

            <StyleDiv>
                <ImageContainer>
                    <StyleImage src={Email_img}/>
                </ImageContainer>
            </StyleDiv>
            <Text2>
                An email has been sent to <Span >{email}</Span> with a link to
                verify your account. If not received,
                <span style={{color: "#3C4E59"}}>
                    please check your spam or bulk folder.</span>
            </Text2>
            <Flex style={{marginTop: "50px"}}>
                <Text>Didn't Receive the Verification Link?</Text>
                <OtpReSend onClick={re_send_email}>
                    RE-SEND
                </OtpReSend>
            </Flex>
            <Foot>
                <Text>© 2022, Stride Ahead™</Text>
            </Foot>
        </RightBanner>

    )
}

export default EmailVerification2;


const OrContainer = styled(Flex)`
  width: 100%;
  margin-top:40px;
  color: #93ACBD;
  .line {  
    display: block;
    height: 2px;
    flex-grow: 1;
    background-color: #93ACBD;
  }
`;

const DividerSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const RightBanner = styled.div`
  padding: 40px 100px;
  width:100%;
  @media(max-width: 576px){
    padding: 0px 0px;
  }
`;

const StyledButton = styled(Button)`
  width: 228.67px;
  height: 60px;
  background: #ffffff;
  margin-top: 5rem;
  padding: 15px 32px;
  border: 2px solid #3f52fd;
  border-radius: 10px;
  color: #3f52fd;
  
   @media(max-width: 576px){
    width: 140px;
    height: 50px;
  }
  
`;

const Heading = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #073b5f;
`;
const SubHeading = styled.h2``;

const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #93acbd;
  margin: 0;
  @media(max-width: 576px){
    font-size: 12px;
  }
`;
const Text2 = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #93ACBD;
  margin: 0;
  @media(max-width: 576px){
    font-size: 12px;
    line-height: 20px;
  }
`;

const Span = styled.span`
  font-weight: 600;
  color: #101518;
  // a{
  // color: #3f52fd;
  // text-decoration:none;
  // }
`;

const Button1 = styled.button`
  
`;
const OtpReSend = styled.span`
  font-weight: 600;
  color: #3f52fd;
  cursor: pointer;
  font-size: 16px;
  margin-left:5px;
  
  @media(max-width: 576px){
    font-size: 12px;
    line-height: 25px;
    
  }
`;

const StyleImage = styled.img`
    width:100%;
    height:100%;
`;
const ImageContainer = styled.div`
    width:313px;
    height:250px;
    margin :50px 0px;
    
    @media(max-width: 576px){
    width: 250.48px;
    height: 200px;
    
  }
    
`;
const StyleDiv = styled.div`
    display:flex;
    justify-content:center;
    width:100%;
`;

const Foot = styled.div`
   @media(min-width:576px){
   display:none;
   }
   @media(max-width:576px){
    margin-top:20px;
    text-align:center;
    display:block;
   }
   @media (min-width: 768px) and (max-width: 1024px){
    margin-top:40px;
    text-align:center;
    display:block;
  }
`;

