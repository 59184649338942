import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import DefaultProfilePicture from '../../assets/default_profile.png';
import useOutsideClick from '../../hooks/useOutsideClick';
import { useCurrentProfile } from '../../hooks/useProfile';
import { API_ENDPOINT } from '../../utils/constants';
import { DropdownItem, DropdownMenu } from './DropdownMenu';
import React from 'react';

const Avatar = () => {
  const history = useHistory();
  const queryClient = useQueryClient();

  const [showDropdown, setShowDropdown] = useState(false);
  const [profilePicture, setProfilePicture] = useState('');
  const avatarRef = useRef(null);

  const { data: currentProfile } = useCurrentProfile();

  useOutsideClick(avatarRef, () => setShowDropdown(false));

  const handleProfilePictureError = () => {
    setProfilePicture(DefaultProfilePicture);
  };

  useEffect(() => {
    setProfilePicture(currentProfile?.profile_pic || '');
  }, [currentProfile]);

  const handleLogout = async () => {
    try {
      await axios.post(API_ENDPOINT + '/logout');
      queryClient.invalidateQueries(['profile', 'current']);
    } catch (error : any) {
      alert('Something went wrong');
    }
  };

  return (
    <Container ref={avatarRef}>
      <img
        src={profilePicture || DefaultProfilePicture}
        alt={currentProfile?.first_name}
        onError={handleProfilePictureError}
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <DropdownMenu column right="0" top="5rem">
          <DropdownItem
            onClick={() => {
              history.push('/account');
              setShowDropdown(false);
            }}
          >
            Account
          </DropdownItem>
          <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
        </DropdownMenu>
      )}
    </Container>
  );
};

export default Avatar;

const Container = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  margin-left: auto;
  margin-right: 2rem;
  cursor: pointer;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
  }
`;
