import React, { FC, useState } from "react";
import styled from "styled-components";
import { slideDown } from "../../../styles/animations";
import Button from "../Button";
import Flex from "../Flex";
import Input from "../Input";
import { Heading2 } from "../Typography";
import useForm from "../../../hooks/useForm";
import axios from "axios";
import {
  REGISTER_FORM_VALIDATION_SCHEMA,
  API_ENDPOINT,
} from "../../../utils/constants";
import { APIError, FetchResponse, FormFields } from "../../../utils/types";
import { useQueryClient } from "react-query";

const AddUser: FC<{ onClose: any }> = ({ onClose }) => {
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const actionOnSubmit = async (data: {
    [key in FormFields]?: string | string[];
  }): Promise<APIError[] | void> => {
    setLoading(true);
    try {
      const postData = { ...data };
      await axios.post<FetchResponse<{ message: string }>>(
        API_ENDPOINT + "/users",
        postData
      );

      setLoading(false);
      queryClient.invalidateQueries(["users"]);
      onClose();
    } catch (error : any) {
      setLoading(false);
      const errors = error.response.data.error as APIError[];
      return errors;
    }
  };
  const { formData, handleChange, handleBlur, handleSubmit } = useForm({
    actions: { onSubmit: actionOnSubmit },
    validatorSchema: REGISTER_FORM_VALIDATION_SCHEMA,
    handleValidationOnBlur: true,
  });
  const isButtonDisabled = () => {
    return !(
      formData.first_name?.value !== "" &&
      formData.first_name?.error === "" &&
      formData.last_name?.value !== "" &&
      formData.last_name?.error === "" &&
      formData.email?.value !== "" &&
      formData.email?.error === "" &&
      formData.password?.value !== "" &&
      formData.password?.error === "" &&
      formData.phone_no?.value !== "" &&
      formData.phone_no?.error === "" &&
      !isLoading
    );
  };

  return (
    <Container column>
      <Heading2>Add User</Heading2>
      <form onSubmit={handleSubmit}>
        <Flex>
          <Input
            label="First Name"
            name="first_name"
            type="text"
            style={{ marginRight: "2rem" }}
            value={formData.first_name?.value}
            error={formData.first_name?.error}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
          />
          <Input
            label="Last Name"
            name="last_name"
            type="text"
            value={formData.last_name?.value}
            error={formData.last_name?.error}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
          />
        </Flex>
        <Input
          label="Email"
          name="email"
          type="email"
          value={formData.email?.value}
          error={formData.email?.error}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
        <Input
          label="Password"
          name="password"
          type="password"
          value={formData.password?.value}
          error={formData.password?.error}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
        <Input
          label="Mobile Number"
          name="phone_no"
          type="phone"
          value={formData.phone_no?.value}
          error={formData.phone_no?.error}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
        />
        <Flex>
          <CancelButton type="button" onClick={onClose}>
            Cancel
          </CancelButton>
          <AddButton
            type="submit"
            isLoading={isLoading}
            disabled={isButtonDisabled()}
          >
            Add
          </AddButton>
        </Flex>
      </form>
    </Container>
  );
};

export default AddUser;

const Container = styled(Flex)`
  width: 50rem;
  height: fit-content;
  padding: 4rem;
  background-color: #fff;
  border-radius: 4px;
  animation: ${slideDown} 0.4s ease-out;

  form {
    margin-top: 2rem;
  }
`;

const CancelButton = styled(Button)`
  width: 10rem;
  height: 5rem;
  margin-right: 1rem;
  margin-left: auto;
  background-color: #fff;
  color: ${(props) => props.theme.colors.grey[500]};
`;

const AddButton = styled(Button)`
  width: 10rem;
  height: 5rem;
`;
