import axios from 'axios';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {FaCamera} from 'react-icons/fa';
import {RiLoader4Line} from 'react-icons/ri';
import {useQueryClient} from 'react-query';
import styled from 'styled-components';
import DefaultProfilePicture from '../../assets/default_profile.png';
import useForm from '../../hooks/useForm';
import {useCurrentProfile} from '../../hooks/useProfile';
import {fadeIn, spin} from '../../styles/animations';
import {
    API_ENDPOINT,
    EDIT_PROFILE_FORM_VALIDATION_SCHEMA,
} from '../../utils/constants';
import {
    APIError,
    FetchResponse,
    FormData as APIFormData,
} from '../../utils/types';
import Button from '../components/Button';
import Flex from '../components/Flex';
import FormStatus from '../components/FormStatus';
import Input from '../components/Input';
import Separator from '../components/Separator';
import {Heading1} from '../components/Typography';
import Toast from "../components/Toast";
import {useLocation, useHistory} from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import PhoneInput2 from "../components/PhoneInput";

const EditProfile = () => {
    const location = useLocation<any>();
    const history = useHistory<any>()
    const [isLoading, setLoading] = useState(false);
    const [isProfilePictureLoading, setProfilePictureLoading] = useState(false);
    const [showToast, setShowToast] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [toastText, setToastText] = useState<string>('');
    const [phone, setPhone] = useState<string>();
    const queryClient = useQueryClient();
    const {data: userProfile} = useCurrentProfile();
    const response = location.state?.response;
    const responseMessage = location.state?.message;

    const setToast = (success: boolean) => {
        setShowToast(true);
        setToastText(
            `${
                success && `${responseMessage}`
            }.`
        );
        setSuccess(success);
    };

    const actionOnSubmit = async (data: any): Promise<APIError[] | string> => {
        delete data['profile_pic'];

        setLoading(true);
        try {
            const {
                data: {
                    data: {message},
                },
            } = await axios.patch<FetchResponse<{ message: string }>>(
                API_ENDPOINT + '/me',
                data
            );

            setLoading(false);
            queryClient.invalidateQueries(['profile', 'current']);
            return message;
        } catch (error : any) {
            setLoading(false);
            const errors = error.response.data.error as APIError[];
            return errors;
        }
    };

    const {
        formData,
        formSubmitResponse,
        setFormData,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useForm({
        actions: {onSubmit: actionOnSubmit},
        validatorSchema: EDIT_PROFILE_FORM_VALIDATION_SCHEMA,
        handleValidationOnBlur: true,
    });

    const handleProfilePictureChange = async (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        if (e?.target?.files) {
            setProfilePictureLoading(true);
            try {
                const file = e.target.files[0];

                const {
                    data: {
                        data: {presigned_url_put},
                    },
                } = await axios.post<FetchResponse<{
                    presigned_url_get: string;
                    presigned_url_put: string;
                }>>(`${API_ENDPOINT}/profile/get-presigned-url`, {
                    file_name: file.name,
                });

                await axios.put(presigned_url_put, file, {
                    withCredentials: false,
                    headers: {'Content-Type': file.type},
                });

                await axios.patch<FetchResponse<{ message: string }>>(
                    API_ENDPOINT + '/me',
                    {
                        profile_pic: file.name,
                    }
                );

                queryClient.invalidateQueries(['profile', 'current']);
                setProfilePictureLoading(false);
            } catch (error : any) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        response && setToast(response)
        setTimeout(() => {
            // @ts-ignore
            return history.replace({...history.location, state: {response: false}});
        }, 5000)
    }, [response])

    useEffect(() => {
        const parsedFormData: APIFormData = {};
        type ProfileFields =
            | 'first_name'
            | 'last_name'
            | 'phone_number'
            | 'profile_pic'
            | 'address'
            | 'zip_code'
            | 'country';
        if (userProfile) {
            const keys = Object.keys(userProfile) as ProfileFields[];
            keys.forEach((key) => {
               parsedFormData[key] = {error: '', value: userProfile[key]};

            });
        }
        setFormData({
            ...formData,
            phone_number: {value: phone, error: ''}
        })
        setFormData(parsedFormData);
        setPhone(parsedFormData.phone_number?.value)

    }, [userProfile, setFormData,phone]);
    console.log(formData, "formdata")

    const isButtonDisabled = () => {
        return !(
            formData.first_name?.value !== '' &&
            formData.first_name?.error === '' &&
            formData.last_name?.value !== '' &&
            formData.last_name?.error === '' &&
            formData.phone_number?.value !== '' &&
            formData.phone_number?.error === ''
        );
    };

    const renderEmailFieldIfAdmin = () => {
        if (userProfile?.user_role === 'admin' || userProfile?.user_role === 'user') {
            return (
                <Input
                    label="Email Address"
                    name="email"
                    type="email"
                    value={formData.email?.value}
                    error={formData.email?.error}
                    placeholder="Enter email address"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // @ts-ignore
                    showSuccessIndicator={userProfile?.is_email_verified}
                />
            );
        }
    };

    return (
        <Container>
            <Heading1 style={{textAlign: 'center'}}>Edit Your Profile</Heading1>

            <Separator/>
            <form onSubmit={handleSubmit}>
                <ProfilePictureContainer>
                    {isProfilePictureLoading ? (
                        <RiLoader4Line className="loader"/>
                    ) : (
                        <img
                            src={formData.profile_pic?.value || DefaultProfilePicture}
                            alt=""
                            onError={() =>
                                setFormData({
                                    ...formData,
                                    profile_pic: {error: '', value: DefaultProfilePicture},
                                })
                            }
                        />
                    )}
                    <label htmlFor="profile_pic">
                        <FaCamera/>
                        <input
                            type="file"
                            name="profile_pic"
                            id="profile_pic"
                            accept="image/png,image/jpeg,image/jpg"
                            onChange={handleProfilePictureChange}
                            hidden
                        />
                    </label>
                </ProfilePictureContainer>
                <Flex>
                    <Input
                        label="First Name"
                        name="first_name"
                        type="text"
                        value={formData.first_name?.value}
                        error={formData.first_name?.error}
                        placeholder="Enter first name"
                        required
                        autoFocus
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{marginRight: '2rem'}}
                    />
                    <Input
                        label="Last Name"
                        name="last_name"
                        type="text"
                        value={formData.last_name?.value}
                        error={formData.last_name?.error}
                        placeholder="Enter last name"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Flex>
                {/*<Input*/}
                {/*    label="Phone number"*/}
                {/*    name="phone_no"*/}
                {/*    type="phone"*/}
                {/*    value={formData.phone_no?.value}*/}
                {/*    error={formData.phone_no?.error}*/}
                {/*    placeholder="Enter phone number"*/}
                {/*    required*/}
                {/*    onChange={handleChange}*/}
                {/*    onBlur={handleBlur}*/}
                {/*    showSuccessIndicator*/}
                {/*/>*/}

                <PhoneInput2
                    label="Phone number"
                    name="phone_number"
                    country={'in'}
                    required={true}
                    value={formData.phone_number?.value || phone}
                    error={formData.phone_number?.error}
                    placeholder="Enter phone number"
                    onChange={(phone: any) => setPhone(phone)}
                    onBlur={handleBlur}
                    //@ts-ignore
                    showSuccessIndicator={userProfile?.is_phone_verified}
                />

                {renderEmailFieldIfAdmin()}
                <Input
                    label="Address"
                    name="address"
                    type="text"
                    value={formData.address?.value}
                    error={formData.address?.error}
                    placeholder="Enter your address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Flex>
                    <Input
                        label="Zip Code"
                        name="zip_code"
                        value={formData.zip_code?.value}
                        error={formData.zip_code?.error}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder="Enter zip code"
                        style={{marginRight: '2rem'}}
                    />
                    <Input
                        label="Country"
                        name="country"
                        type="text"
                        value={formData.country?.value}
                        error={formData.country?.error}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter country"
                    />
                </Flex>
                <StyledButton
                    type="submit"
                    disabled={isButtonDisabled()}
                    isLoading={isLoading}
                >
                    Save Information
                </StyledButton>
            </form>
            {formSubmitResponse && (
                <FormStatus
                    align="center"
                    className={formSubmitResponse.status}
                    style={{marginTop: '2rem'}}
                >
                    {formSubmitResponse.message}
                </FormStatus>
            )}
            {showToast && (
                <Toast
                    onClose={(showToast: any) => setShowToast(showToast)}
                    success={success}
                >
                    {toastText}
                </Toast>
            )}
        </Container>
    );
};

export default EditProfile;

const Container = styled.div`
  width: 45rem;
  margin: 0 auto;
  padding: 4rem 0;
  animation: ${fadeIn} 0.5s;
`;

const ProfilePictureContainer = styled(Flex)`
  width: 10rem;
  height: 10rem;
  position: relative;
  border-radius: 5rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  overflow: hidden;

  :hover label {
    opacity: 1;
  }

  .loader {
    width: 50%;
    height: 50%;
    margin: auto;
    animation: ${spin} 0.5s infinite linear;
  }

  label {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 3.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000090;
    opacity: 0;
    cursor: pointer;
    transition: all 0.2s;
  }

  input {
    width: 100%;
    height: 100%;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;
