import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { useEffect, useState } from "react";
import { RiPencilLine } from "react-icons/ri";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import styled from "styled-components";
import useUsers from "../../hooks/useUsers";
import Button from "../components/Button";
import Flex from "../components/Flex";
import Modal from "../components/Modal";
import AddUser from "../components/popups/AddUser";
import {
  Pagination,
  Table,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "../components/Table";
import { Heading3 } from "../components/Typography";

dayjs.extend(relativeTime);

const UsersList = () => {
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const [search, setSearch] = useState("");

  const { isLoading, isSuccess, data } = useUsers({
    page,
    search,
  });

  useEffect(() => {
    if (search !== "") setPage(1);
    return;
  }, [search]);

  const history = useHistory();

  const openAddUserPopup = () => {
    setShowModal(true);
  };

  const getSlno = (index: number, page: number) => {

    let initialSlNo = index + 1;

      if(page > 1) {
        return initialSlNo + 20 * (page-1);
      }else{
        return initialSlNo
      }
  }

  const renderUserList = () => {
    if (isLoading) {
      return (
        <TR>
          <TD colSpan={5} centered>
            Loading...
          </TD>
        </TR>
      );
    } else if (isSuccess) {
      return data?.users.map((user, index) => (
        <TR key={user.user_id}>
          <TD maxWidth="12%" style={{ paddingLeft: "4rem" }}>
            {getSlno(index,page)}
          </TD>
          <TD maxWidth="21%">
            {user.first_name} {user.last_name}
          </TD>
          <TD maxWidth="23%">{user.email}</TD>
          <TD maxWidth="18%">
            {user.last_login ? dayjs(user.last_login).fromNow() : "--"}
          </TD>
          <TD maxWidth="15%">
            {user.is_active ? (
              <StatusContainer className="active">Online</StatusContainer>
            ) : (
              <StatusContainer className="inactive">Offline</StatusContainer>
            )}
          </TD>
          <TD maxWidth="15%">
            <EditButton onClick={() => history.push(`/users/${user.user_id}`)}>
              <RiPencilLine />
            </EditButton>
          </TD>
        </TR>
      ));
    }
  };

  return (
    <Container column>
      <Header align="center" justify="space-between">
        <Heading3>Users</Heading3>
        <input
          value={search}
          placeholder="Search user"
          style={{ outline: "none", border: "0" }}
          onChange={(e) => setSearch(e.target.value)}
        />
        <AddUserButton onClick={openAddUserPopup}>Add User</AddUserButton>
      </Header>
      <TableContainer>
        <Table style={{ flexGrow: 1, backgroundColor: "#fff" }}>
          <THead>
            <TR>
              <TH style={{ paddingLeft: "4rem" }}>S.No.</TH>
              <TH>Name</TH>
              <TH>Email</TH>
              <TH>Last Login</TH>
              <TH>Status</TH>
              <TH>Edit</TH>
            </TR>
          </THead>
          <TBody>{renderUserList()}</TBody>
        </Table>
      </TableContainer>
      {!isLoading && (
        <Pagination
          currentPage={page}
          nextPageChangeHandler={
            data?.pagination_data.page_next
              ? function () {
                  setPage((currentPage) => currentPage + 1);
                }
              : undefined
          }
          prevPageChangeHandler={
            data?.pagination_data.page_prev
              ? function () {
                  setPage((currentPage) => currentPage - 1);
                }
              : undefined
          }
        />
      )}
      {showModal && (
        <Modal>
          <AddUser onClose={() => setShowModal(false)} />
        </Modal>
      )}
    </Container>
  );
};

export default UsersList;

const Container = styled(Flex)`
  width: 100%;
`;

const Header = styled(Flex)`
  width: 100%;
  padding: 1rem 4rem;
  border-top: 1px solid ${(props) => props.theme.colors.grey[200]};
  border-bottom: 1px ridge ${(props) => props.theme.colors.grey[200]};
  background-color: #fff;
`;

const AddUserButton = styled(Button)`
  width: 16rem;
  height: 4rem;
`;

const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const EditButton = styled.button`
  outline: none;
  border: none;
  height: fit-content;
  padding: 0.5rem;
  padding-bottom: 0;
  border-radius: 4px;
  background: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey[100]};
  }

  font-size: 2rem;
  cursor: pointer;
`;

const StatusContainer = styled.span`
  border-radius: 1.1rem;
  padding: 0.5rem 1rem;
  font-weight: 500;

  &.active {
    color: ${(props) => props.theme.colors.green[500]};
    background-color: ${(props) => props.theme.colors.green[200]};
  }

  &.inactive {
    color: ${(props) => props.theme.colors.grey[500]};
    background-color: ${(props) => props.theme.colors.grey[200]};
  }
`;
