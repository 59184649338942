import {ValidationSchema} from './types';

export const TOAST_DELAY: number = 5000;

export const LOGIN_FORM_VALIDATION_SCHEMA: ValidationSchema = {
    email: {
        name: 'Email address',
        required: true,
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorText: 'Please enter a valid email address',
    },
    password: {
        name: 'Password',
        required: true,
    },
};
export const LOGIN_WITH_PHONE_FORM_VALIDATION_SCHEMA: ValidationSchema = {
    phone_no: {
        name: 'Phone number',
        required: true,
        regex: /^\d{10}$/,
        errorText: 'Please enter a valid phone number (e.g., 9876543210)',
    },
};

export const REGISTER_FORM_VALIDATION_SCHEMA: ValidationSchema = {
    first_name: {
        name: 'First name',
        required: true,
    },
    last_name: {
        name: 'Last name',
        required: true,
    },
    password: {
        name: 'Password',
        required: true,
    },
    email: {
        name: 'Email address',
        required: true,
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorText: 'Please enter a valid email address',
    },
    phone_no: {
        name: 'Phone number',
        required: true,
        regex: /^\d{10}$/,
        errorText: 'Please enter a valid phone number (e.g., 9876543210)',
    },
};

export const REGISTER_WITH_PHONE_FORM_VALIDATION_SCHEMA: ValidationSchema = {
    phone_no: {
        name: 'Phone number',
        required: true,
        regex: /^\d{10}$/,
        errorText: 'Please enter a valid phone number (e.g., 9876543210)',
    },
};

export const EDIT_PROFILE_FORM_VALIDATION_SCHEMA: ValidationSchema = {
    first_name: {
        name: 'First name',
        required: true,
    },
    last_name: {
        name: 'Last name',
        required: true,
    },
    email: {
        name: 'Email address',
        required: true,
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorText: 'Please enter a valid email address',
    },
    phone_number: {
        name: 'Phone number',
        required: true,
        regex: /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{12}$/,
        errorText: 'Please enter a valid phone number (e.g., 9876543210)',
    },
};

export const ADD_SERVICE_VALIDATION_SCHEMA: ValidationSchema = {
    service_id: {
        name: 'Service ID',
        required: true,
    },
    service_name: {
        name: 'Service Name',
        required: true,
    },
    service_url: {
        name: 'Service URL',
        required: true,
        regex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{0,6}(:\d{0,4})?\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        errorText: 'Please enter a valid URL',
    },
};

export const CHANGE_PASSWORD_VALIDATION_SCHEMA: ValidationSchema = {
    old_password: {
        name: 'Old Password',
        required: true,
    },
    new_password: {
        name: 'New Password',
        required: true,
    },
};

export const REGISTER_GOOGLE_FORM_VALIDATION: ValidationSchema = {
    phone_no: {
        name: 'Phone number',
        required: true,
        regex: /\d{10}/,
        errorText: 'Please enter a valid phone number (e.g., 9876543210)',
    },
};

export const PAGINATION_LIMIT = 20;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT as string;
export const GOOGLE_CLIENT_ID = process.env
    .REACT_APP_GOOGLE_CLIENT_ID as string;
export const LINKEDIN_CLIENT_ID = process.env
    .REACT_APP_LINKEDIN_CLIENT_ID as string;
export const APPLE_CLIENT_ID = process.env
    .REACT_APP_APPLE_CLIENT_ID as string;
export const LINKEDIN_RE_DIRECT_URI = process.env
    .REACT_APP_LINKEDIN_RE_DIRECT_URI as string;
export const APPLE_RE_DIRECT_URI = process.env
    .REACT_APP_APPLE_RE_DIRECT_URI as string;
