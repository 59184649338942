import axios from "axios";
import { API_ENDPOINT } from "./constants";

const fetchSSOToken = async (token: string = "") => {
  try {
    const headers = token !== "" ? { refresh_token: token } : {};
    const {
      data: { data },
    } = await axios.post(`${API_ENDPOINT}/sso`, {
      headers: headers,
    });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export default fetchSSOToken;
