import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { useCurrentProfile } from "../../hooks/useProfile";
import Flex from "../components/Flex";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import UserServices from "./UserServices";

const UserDashboard = () => {
  const { data: userProfile } = useCurrentProfile();

  return (
    <Container>
      <Navbar />
      <Sidebar />
      <Content>
        <Switch>
          <Route path="/" exact component={EditProfile} />
          <Route path="/account" exact component={EditProfile} />
          {userProfile?.has_password && (
            <Route path="/account/password" component={ChangePassword} />
          )}
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

export default UserDashboard;

const Container = styled(Flex)`
  min-height: 100vh;
`;

const Content = styled(Flex)`
  width: calc(100% - 26rem);
  margin-top: 7rem;
  margin-left: 26rem;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.grey[100]};
`;
