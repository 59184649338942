import styled from 'styled-components';

const Separator = styled.span`
  display: block;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  margin: 2rem 0;
`;

export default Separator;
