import { FC } from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import styled from 'styled-components';
import Flex from './Flex';
import { Text2Css } from './Typography';
import React from 'react';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  ${Text2Css}
`;

export const THead = styled.thead``;
export const TBody = styled.tbody``;
export const TR = styled.tr`
  text-align: left;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};
`;
export const TH = styled.th<{ centered?: boolean }>`
  ${(props) => props.centered && `text-align: center`};
  color: ${(props) => props.theme.colors.grey[600]};
  height: 4rem;
`;
export const TD = styled.td<{ maxWidth?: string; centered?: boolean }>`
  width: ${(props) => props.maxWidth || '10rem'};
  height: 5rem;
  ${(props) => props.centered && `text-align: center`};
  color: ${(props) => props.theme.colors.grey[500]};
`;

interface PaginationProps {
  currentPage: number;
  nextPageChangeHandler?: any;
  prevPageChangeHandler?: any;
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  nextPageChangeHandler,
  prevPageChangeHandler,
}) => {
  return (
    <PaginationContainer align="center">
      <button
        className="left"
        disabled={!prevPageChangeHandler}
        onClick={prevPageChangeHandler}
      >
        <RiArrowLeftSLine />
      </button>
      <span className="current-page">{currentPage}</span>
      <button
        className="right"
        disabled={!nextPageChangeHandler}
        onClick={nextPageChangeHandler}
      >
        <RiArrowRightSLine />
      </button>
    </PaginationContainer>
  );
};

const PaginationContainer = styled(Flex)`
  padding: 1.6rem 4rem;
  ${Text2Css}

  .current-page {
    margin: 0 1rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    height: 3.5rem;
    width: 3.5rem;
    padding: 0;
    font-size: 2.2rem;
    background-color: #fff;
    border: 1px solid ${(props) => props.theme.colors.grey[300]};
    cursor: pointer;

    :disabled {
      cursor: not-allowed;
    }

    :not(:disabled):hover {
      filter: invert(8%);
    }

    &.left {
      border-radius: 4px 0 0 4px;
    }

    &.right {
      border-radius: 0 4px 4px 0;
    }
  }
`;
