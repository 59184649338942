import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import { API_ENDPOINT, APPLE_CLIENT_ID } from "../../utils/constants";
import { APIError, FetchResponse, FormFields } from "../../utils/types";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import FormStatus from "../components/FormStatus";
import { LinkedIn } from "react-linkedin-login-oauth2";
import AppleLogin from "react-apple-login";
import GoogleLogin from "react-google-login";
import LinkedInIcon from "../../assets/svg/linkedin.svg";
import GoogleIcon from "../../assets/svg/googleIcon.svg";
import AppleIcon from "../../assets/svg/apple.svg";
import {
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID,
  APPLE_RE_DIRECT_URI,
  LINKEDIN_RE_DIRECT_URI,
} from "../../utils/constants";

function LoginWithSocials() {
  const history = useHistory();
  const [alertStyle, setAlertStyle] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [messages, setMessages] = useState<any>({ type: "", message: "" });

  const [googleLoginResponse, setGoogleLoginResponse] = useState<{
    status: "success" | "fail";
    message: string;
  }>();

  const handleCallBackResponse = async (response: any) => {
    console.log(response, "google");
    const { email, familyName, givenName } = response.profileObj;
    const tokenId = response.tokenId;

    await fetch(API_ENDPOINT + "/google-sign-up", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        first_name: givenName,
        last_name: familyName,
        email: email,
        google_auth_token: tokenId,
      }),
    })
      .then((res: any) => res.json())
      .then((data) => {
        if (data?.data?.google_auth_token_verification_status) {
          const urlSearchParams = new URLSearchParams(history.location.search);
          const redirectUrl: any = urlSearchParams.get("redirect_to");
          let redirectTo = new URL(decodeURIComponent(redirectUrl));
          if (redirectUrl !== null) {
            window.location.href = redirectTo.href;
          } else {
            history.push({
              pathname: "/",
              state: {
                response: true,
                message: "Login with Google Successfully",
              },
            });
          }
        } else {
          setMessages({
            type: "error",
            message: "Login fail, Please try again",
          });
        }
      });
  };

  const linkedinHandleResponse = async (code: any) => {
    console.log({ code }, "linkedin");
    await fetch(API_ENDPOINT + "/linkedin-sign-up", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        code: code,
      }),
    })
      .then((res: any) => res.json())
      .then((data) => {
        console.log(data?.data?.refresh_token, "linkedin data");
        if (data?.data?.refresh_token) {
          const urlSearchParams = new URLSearchParams(history.location.search);
          const redirectUrl: any = urlSearchParams.get("redirect_to");
          let redirectTo = new URL(decodeURIComponent(redirectUrl));
          if (redirectUrl !== null) {
            window.location.href = redirectTo.href;
          } else {
            history.push({
              pathname: "/",
              state: {
                response: true,
                message: "Login with Linkedin Successfully",
              },
            });
          }
        } else {
          setMessages({
            type: "error",
            message: "Login fail, Please try again",
          });
        }
      });
  };

  const appleHandleResponse = async (response: any) => {
    await fetch(API_ENDPOINT + "/apple-sign-up", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        user: "",
        email: response?.user?.email,
        fullName: {
          firstName: response?.user?.name?.firstName || null,
          lastName: response?.user?.name?.lastName || null,
        },
        realUserStatus: 1,
        nonce: "",
        identityToken: response?.authorization?.id_token,
        authorizationCode: response?.authorization?.code,
        clientId: APPLE_CLIENT_ID,
      }),
    })
      .then((res: any) => res.json())
      .then((data) => {
        if (data?.data?.refresh_token) {
          const urlSearchParams = new URLSearchParams(history.location.search);
          const redirectUrl: any = urlSearchParams.get("redirect_to");
          let redirectTo = new URL(decodeURIComponent(redirectUrl));
          if (redirectUrl !== null) {
            window.location.href = redirectTo.href;
          } else {
            history.push({
              pathname: "/",
              state: {
                response: true,
                message: "Login with Apple Successfully",
              },
            });
          }
        } else {
          setMessages({
            type: "error",
            message: "Login fail, Please try again",
          });
        }
      })
      .catch((error) => {
        setMessages({
          type: "error",
          message: "Login fail, Please try again",
        });
      });
  };

  useEffect(() => {
    messages.message ? setShowAlert(true) : setShowAlert(false);
  }, [messages]);

  useEffect(() => {
    messages?.type === "success" ? setAlertStyle(true) : setAlertStyle(false);
  }, [messages]);

  return (
    <div style={{ width: "100%", marginTop: "20px" }}>
      <SocialIconContainer>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          render={(renderProps) => {
            return (
              <img
                src={GoogleIcon}
                alt="google icon"
                onClick={renderProps.onClick}
                style={{ cursor: "pointer" }}
              />
            );
          }}
          buttonText="Log in with Google"
          onSuccess={handleCallBackResponse}
          onFailure={(error) => {
            if (error.error === "access_denied") {
              setGoogleLoginResponse({
                status: "fail",
                message: "Please allow access to login successfully",
              });
            }
          }}
          cookiePolicy={"single_host_origin"}
        />
        <LinkedIn
          clientId={LINKEDIN_CLIENT_ID}
          redirectUri={LINKEDIN_RE_DIRECT_URI}
          scope="r_liteprofile,r_emailaddress"
          onSuccess={linkedinHandleResponse}
          onError={(error: any) => {
            setMessages({
              type: "error",
              message: `${error}`,
            });
          }}
        >
          {({ linkedInLogin }: any) => (
            <img
              src={LinkedInIcon}
              onClick={linkedInLogin}
              style={{ cursor: "pointer" }}
              alt="linkedin"
            />
          )}
        </LinkedIn>
        <AppleLogin
          clientId={APPLE_CLIENT_ID}
          redirectURI={APPLE_RE_DIRECT_URI}
          callback={(response: any) => appleHandleResponse(response)} // Catch the response
          scope="name email"
          responseMode="form_post"
          usePopup={true}
          render={(
            renderProps //Custom Apple Sign in Button
          ) => (
            <img
              src={AppleIcon}
              onClick={renderProps.onClick}
              style={{ cursor: "pointer" }}
              alt="google"
            />
          )}
        />
      </SocialIconContainer>
      {showAlert && (
        <div
          className={alertStyle ? "alert alert-success " : "alert alert-danger"}
          role="alert"
        >
          {messages?.message}
        </div>
      )}
      {googleLoginResponse && (
        <FormStatus align="center" className={googleLoginResponse.status}>
          {googleLoginResponse.message}
        </FormStatus>
      )}
    </div>
  );
}

export default LoginWithSocials;

const SocialIconContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 0 auto;
  @media (max-width: 576px) {
    gap: 20px;
  }
`;
