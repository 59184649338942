import {
  RiDashboardLine,
  RiGroupLine,
  RiKey2Line,
  RiStackLine,
  RiUser3Line,
} from "react-icons/ri";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useCurrentProfile } from "../../hooks/useProfile";
import Flex from "./Flex";
import Separator from "./Separator";
import React from "react";

const Sidebar = () => {
  const { data: currentProfile } = useCurrentProfile();

  const renderNavItemsBasedOnUserType = () => {
    switch (currentProfile?.user_role) {
      case "user": {
        return (
          <NavItemsContainer column>
            {/* <NavItem exact to="/">
              <RiDashboardLine />
              Dashboard
            </NavItem> */}
            <StyledSeparator />
            <NavItem exact to="/account">
              <RiUser3Line />
              Profile
            </NavItem>
            {currentProfile.has_password && (
              <NavItem to="/account/password">
                <RiKey2Line />
                Change Password
              </NavItem>
            )}
            <StyledSeparator />
          </NavItemsContainer>
        );
      }
      case "admin":
        return (
          <NavItemsContainer column>
            <NavItem exact to="/">
              <RiDashboardLine />
              Dashboard
            </NavItem>
            <NavItem to="/users">
              <RiGroupLine />
              Users
            </NavItem>
            <NavItem exact to="/services">
              <RiStackLine />
              Services
            </NavItem>
            <StyledSeparator />
            <NavItem exact to="/account">
              <RiUser3Line />
              Profile
            </NavItem>
            <NavItem to="/account/password">
              <RiKey2Line />
              Change Password
            </NavItem>
            <StyledSeparator />
          </NavItemsContainer>
        );
    }
  };
  return <Container column>{renderNavItemsBasedOnUserType()}</Container>;
};

export default Sidebar;

const Container = styled(Flex)`
  height: 100%;
  width: 26rem;
  padding-top: 8rem;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 1px 1rem ${({ theme }) => theme.colors.grey[100]};
`;

const StyledSeparator = styled(Separator)`
  margin: 0;
`;

const NavItemsContainer = styled(Flex)`
  width: 100%;
`;

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 5rem;
  width: 100%;
  padding-left: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Poppins";
  color: ${({ theme }) => theme.colors.grey[400]};
  text-decoration: none;

  &.active {
    color: ${({ theme }) => theme.colors.blue[500]};
  }

  svg {
    font-size: 2rem;
    margin-right: 1rem;
  }
`;
