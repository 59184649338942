import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { API_ENDPOINT } from '../utils/constants';
import { APIError, UsersCount } from '../utils/types';

const useUsersCount = () => {
  const fetchUsersCount = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(API_ENDPOINT + '/users/count');
      return data;
    } catch (error : any) {
      throw error.response.data;
    }
  };

  return useQuery<UsersCount, AxiosResponse<APIError>>(
    ['users', 'count'],
    fetchUsersCount,
    {
      retry: false,
    }
  );
};

export default useUsersCount;
